.starts-with-domain-domainpage:before {
  top: 0; }

.starts-with-domain-domainpage2:before {
  top: -141px;
  width: 50%;
  height: 935px;
  background-image: url("../img/desktop/starts-with-domain-domainpage.png"); }

.infobox-wide {
  max-width: 1135px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  background-image: url("../img/bg/domain1-infobox.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box;
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
  padding: 35px 32px;
  margin: 135px auto 70px; }
  .infobox-wide img {
    margin-right: 36px; }
  .infobox-wide p {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.6;
    text-transform: uppercase; }

.infobox-item {
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 60px; }

.infobox-inner {
  background-image: url("../img/bg/domain1-infobox2.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
  background-clip: padding-box;
  box-shadow: 0 0 36px rgba(152, 38, 137, 0.31);
  padding: 25px 15px 29px; }

.infobox-circle {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 101px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(243, 36, 209, 0.16);
  margin: 0 auto 11px; }

.infobox-title {
  font-size: 25px;
  font-weight: 700;
  color: #10162B;
  line-height: 1.4;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 13px; }
  .infobox-title:after {
    content: '';
    display: block;
    width: 109px;
    height: 3px;
    background-color: #EE3194;
    margin: 23px auto 0; }

.infobox-price {
  text-align: center;
  padding-top: 22px; }
  .infobox-price-discount {
    padding-top: 0; }
  .infobox-price-sale {
    font-size: 31px;
    font-weight: 500;
    color: #BABBC3;
    line-height: 1.2580645161;
    text-decoration: line-through; }
  .infobox-price-old {
    font-size: 46px;
    font-weight: 700;
    color: #10162B;
    line-height: 1.1;
    margin-bottom: 7px; }
  .infobox-price-vat {
    font-size: 26px;
    color: #10162B;
    line-height: 1.1; }

.infobox-comment {
  display: flex;
  align-items: center; }
  .infobox-comment-circle {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    min-width: 47px;
    height: 47px;
    background-color: #1A1D39;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box;
    box-shadow: 0 0 6px rgba(242, 29, 164, 0.55);
    margin-right: 15px; }
  .infobox-comment p {
    font-size: 21px;
    color: #707070;
    line-height: 1.2380952381; }

.accordion-domain {
  padding-top: 75px; }
  .accordion-domain h2 {
    margin-bottom: 55px; }
  .accordion-domain .accordion-item {
    margin-bottom: 36px; }

@media screen and (max-width: 1199px) {
  .starts-with-domain-domainpage2:before {
    top: -70px; } }

@media screen and (max-width: 1024px) {
  .infobox-wide {
    padding: 32px 15px;
    margin: 80px auto 70px; }
    .infobox-wide img {
      margin-right: 24px; }
  .infobox-circle {
    margin: 0 auto 21px; }
  .infobox-title {
    font-size: 20px;
    line-height: 1.25;
    margin-bottom: 20px; }
  .infobox-price {
    padding-top: 39px; }
    .infobox-price-discount {
      padding-top: 0; }
  .infobox-comment-circle {
    min-width: 73px;
    width: 73px;
    height: 73px; }
    .infobox-comment-circle img {
      width: 31px;
      height: auto; }
  .accordion-domain {
    padding-top: 66px; }
    .accordion-domain .accordion-domain h2 {
      margin-bottom: 90px; }
  .starts-with-domain-domainpage2 h1 {
    padding-top: 31px;
    padding-right: 0;
    margin-bottom: 20px; }
  .starts-with-domain-domainpage2 .section-subtitle-bold {
    font-weight: 400; }
  .starts-with-domain-domainpage2 .col-6 {
    max-width: 100%;
    flex-basis: 100%; }
  .starts-with-domain-domainpage2 .form-search {
    max-width: 420px;
    margin-left: 0; }
  .starts-with-domain-domainpage2:before {
    display: none;
    width: 875px;
    height: 935px; } }

@media screen and (max-width: 575px) {
  .boxes .infobox-wide {
    flex-wrap: wrap;
    margin: 55px auto 57px; }
    .boxes .infobox-wide img {
      width: 66px;
      height: auto;
      margin-right: auto;
      margin-bottom: 31px;
      margin-left: auto; }
    .boxes .infobox-wide p {
      max-width: 100%;
      flex-basis: 100%;
      font-size: 15px;
      line-height: 1.6666666667;
      text-align: center; }
  .boxes .infobox-item {
    margin-bottom: 36px; }
  .boxes .infobox-inner {
    padding: 25px 0 26px; }
  .boxes .infobox-circle {
    width: 83px;
    height: 83px;
    margin: 0 auto 15px; }
  .boxes .infobox-title {
    line-height: 1.4;
    padding: 0 15px;
    margin-bottom: 14px; }
  .boxes .infobox-price-sale {
    font-size: 25px;
    line-height: 1.24;
    margin-bottom: 3px; }
  .boxes .infobox-price-old {
    font-size: 38px; }
  .boxes .infobox-price-vat {
    font-size: 21px; }
  .boxes .infobox-comment-circle {
    width: 53px;
    min-width: 53px;
    height: 53px; }
    .boxes .infobox-comment-circle img {
      width: 19px; }
  .boxes .infobox-comment p {
    font-size: 13px; }
  .accordion-domain {
    padding-top: 60px;
    padding-bottom: 19px; }
    .accordion-domain h2 {
      margin-bottom: 48px; } }
