.knowledge {
  position: relative; }
  .knowledge h1 {
    padding-top: 14.3vw;
    margin-bottom: 35px; }
  .knowledge h2 {
    margin-bottom: 15px; }
  .knowledge h3 {
    margin-bottom: 53px; }
  .knowledge:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 991px;
    width: 50vw;
    height: 1069px;
    background-image: url("../img/desktop/starts-with-domain.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.knowledge-categories {
  padding-top: calc(14.3vw - 50px);
  padding-bottom: 40px; }
  .knowledge-categories .row {
    display: block;
    column-count: 3;
    column-gap: 30px; }
  .knowledge-categories .kl-inner {
    display: inline-block;
    background-image: url("../img/bg/knowledge.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-border-radius: 44px;
    -moz-border-radius: 44px;
    border-radius: 44px;
    background-clip: padding-box;
    box-shadow: 0 0 36px rgba(152, 38, 137, 0.31);
    padding: 45px 30px 43px;
    margin-top: 50px; }
  .knowledge-categories .kl-title {
    font-size: 35px;
    font-weight: 700;
    color: #10162B;
    line-height: 1.2571428571;
    text-transform: uppercase;
    margin-bottom: 32px; }
    .knowledge-categories .kl-title:after {
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background-color: #EE3194;
      margin-top: 22px; }
  .knowledge-categories .kl-subtitle {
    font-size: 15px;
    font-weight: 600;
    color: rgba(26, 29, 56, 0.3);
    line-height: 1.2;
    text-transform: uppercase;
    margin-top: -7px;
    margin-bottom: 25px; }
  .knowledge-categories .kl-link {
    position: relative;
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #707070;
    line-height: 1.45;
    padding-left: 30px;
    margin-bottom: 24px; }
    .knowledge-categories .kl-link:before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url("../img/icons/search.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto; }

@media screen and (max-width: 1024px) {
  .knowledge h1 {
    padding-top: 44.5vw;
    margin-bottom: 15px; }
  .knowledge h2 {
    margin-bottom: 0; }
    .knowledge h2 br {
      display: none; }
  .knowledge h3 {
    margin-bottom: 38px; }
  .knowledge .form-search .form-submit {
    max-width: 120px;
    flex-basis: 120px; }
  .knowledge:before {
    max-width: 991px;
    width: 100%;
    height: 920px;
    background-image: url("../img/tablet/page-knowledge.png"); }
  .knowledge-categories .row {
    column-count: 2; }
  .knowledge-categories .kl-col {
    padding-top: 0; } }

@media screen and (max-width: 660px) {
  .knowledge .col-md-8 {
    max-width: 100%;
    flex-basis: 100%; }
  .knowledge h1 {
    text-align: center;
    padding-top: 0;
    margin-top: -38px; }
  .knowledge h2 {
    font-size: 14px;
    line-height: 1.3571428571;
    text-align: center; }
  .knowledge h3 {
    font-size: 14px;
    line-height: 1.3571428571;
    text-align: center;
    margin-bottom: 27px; }
  .knowledge:before {
    display: none; }
  .knowledge-categories .row {
    column-count: 1; }
  .knowledge-categories .kl-col {
    padding: 0 15px; }
  .knowledge-categories .kl-inner {
    padding: 40px 30px 29px;
    margin-top: 43px; }
  .knowledge-categories .kl-title {
    font-size: 30px;
    line-height: 1.2666666667;
    margin-bottom: 26px; }
    .knowledge-categories .kl-title:after {
      margin-top: 18px; }
  .knowledge-categories .kl-link {
    font-size: 17px;
    line-height: 1.4705882353;
    margin-bottom: 21px; } }
