.container {
  max-width: 1310px;
  padding: 0 15px;
  margin: 0 auto; }
  .container, .container-fluid {
    width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px; }
  .row-12 {
    margin-right: -12px;
    margin-left: -12px; }

[class^="col-"] {
  width: 100%;
  padding: 0 15px; }

.col {
  flex-grow: 1; }
  .col-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-20 {
    max-width: 20%;
    flex-basis: 20%; }
  .col-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-6 {
    max-width: 50%;
    flex-basis: 50%; }
    .col-6-offset {
      margin-left: 50%; }
  .col-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-12 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-tablet {
    display: none; }

@media screen and (max-width: 1366px) {
  .container {
    max-width: 1140px; } }

@media screen and (max-width: 1199px) {
  .container {
    max-width: 960px; }
  .col-lg-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-lg-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-lg-12 {
    max-width: 100%;
    flex-basis: 100%; } }

@media screen and (max-width: 1024px) {
  .container {
    max-width: 720px; }
  .col-md-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-md-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-md-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-md-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-md-12 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-tablet {
    display: block; } }

@media screen and (max-width: 768px) {
  .container {
    /*max-width: 540px;*/
    max-width: calc(100% - 50px);
    margin: 0 auto; }
  .col-sm-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-sm-12 {
    max-width: 100%;
    flex-basis: 100%; } }

@media screen and (max-width: 660px) {
  .container {
    max-width: 100%; }
  .col-xs-12 {
    max-width: 100%;
    flex-basis: 100%;
    margin-left: 0; } }
