.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100px;
  z-index: 1001;
  max-width: 1128px;
  width: calc(100% - 30px);
  background-color: #FFFFFF;
  background-image: url("../img/bg/popup.png");
  background-position: calc(100% + 30px) calc(100% + 34px);
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(38, 49, 152, 0.31);
  -ms-transform: translate(-50%, 10%);
  -webkit-transform: translate(-50%, 10%);
  -moz-transform: translate(-50%, 10%);
  -o-transform: translate(-50%, 10%);
  transform: translate(-50%, 10%);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  margin: 0 auto; }
  .popup-content {
    max-width: 790px;
    width: 100%;
    padding: 57px 20px;
    margin: 0 auto; }
    .popup-content .form-search {
      max-width: unset;
      width: 100%;
      margin: 0 auto 40px; }
    .popup-content .form-input {
      max-width: calc(100% - 260px);
      flex-basis: calc(100% - 260px); }
    .popup-content .popup-text {
      font-size: 20px;
      font-weight: 500;
      color: #BABBC3;
      line-height: 1.1; }
    .popup-content .chk-radio {
      font-size: 20px;
      font-weight: 500;
      color: #BABBC3;
      line-height: 1.1;
      margin-top: 40px; }
      .popup-content .chk-radio .checkmark {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.16); }
  .popup-error {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    background: #ef3295;
    background: linear-gradient(128deg, #ef3295 0%, #c624ff 200%);
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    background-clip: padding-box;
    box-shadow: 0 6px 20px rgba(26, 29, 57, 0.53);
    padding: 5px 15px;
    margin-bottom: 40px; }
    .popup-error img {
      margin-right: 15px; }
    .popup-error p {
      font-size: 15px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 1.2;
      text-transform: uppercase; }
  .popup-close {
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 49px;
    z-index: 2; }
  .popup.active {
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible; }

@media screen and (max-width: 768px) {
  .popup-content {
    padding: 65px 20px 30px; }
    .popup-content .popup-text, .popup-content .chk-radio {
      font-size: 15px; }
    .popup-content .form-search {
      margin: 0 auto 20px; }
    .popup-content .popup-text {
      margin-bottom: 20px; }
    .popup-content .chk-radio {
      margin-top: 20px; }
  .popup-close {
    top: 25px;
    right: 25px; } }

@media screen and (max-width: 660px) {
  .popup-content {
    position: relative;
    max-height: calc(100vh - 102px);
    overflow-y: scroll; }
    .popup-content .form-input {
      max-width: calc(100% - 173px);
      flex-basis: 100%; } }
