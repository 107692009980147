.footer {
  background-color: #10162B;
  padding-top: 32px; }
  .footer .inner {
    display: flex;
    align-items: center; }
  .footer .icon {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    max-width: 48px;
    flex-basis: 48px;
    min-width: 48px;
    height: 48px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    background-clip: padding-box;
    border: 2px solid rgba(255, 255, 255, 0.06);
    margin-right: 20px; }
  .footer p, .footer a {
    display: block;
    font-size: 15px;
    font-weight: 500 !important;
    color: #FFFFFF;
    line-height: 1.2;
    text-transform: uppercase; }
  .footer .copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 40px;
    padding-bottom: 25px;
    margin-top: 35px; }
    .footer .copyright .col-6:first-child img {
      margin-right: 20px; }
    .footer .copyright .col-6:first-child p {
      font-size: 14px;
      line-height: 1.2;
      text-transform: none; }
    .footer .copyright .col-right {
      text-align: right; }
      .footer .copyright .col-right a {
        margin-left: 10px; }
        .footer .copyright .col-right a img {
          margin: 0 auto; }
        .footer .copyright .col-right a:last-child {
          margin-top: 10px; }
    .footer .copyright a {
      display: inline-block;
      font-size: 12px;
      text-decoration: underline; }

@media screen and (max-width: 1199px) {
  .footer .row:not(.copyright) {
    justify-content: center; }
  .footer .copyright {
    margin-top: 20px; }
  .footer .col-20 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
    margin-bottom: 15px; } }

@media screen and (max-width: 768px) {
  .footer .row:not(.copyright) {
    justify-content: flex-start; }
  .footer .col-20 {
    max-width: 50%;
    flex-basis: 50%;
    margin-bottom: 15px; } }

@media screen and (max-width: 660px) {
  .footer .col-20, .footer .col-6 {
    max-width: 100%;
    flex-basis: 100%; }
  .footer .col-20 .inner {
    max-width: 230px;
    margin: 0 auto; }
  .footer .copyright .col-right {
    text-align: center; }
    .footer .copyright .col-right a {
      margin-bottom: 15px; }
  .footer .copyright a {
    display: block; }
  .footer .copyright .inner {
    justify-content: center;
    margin-bottom: 15px; } }
