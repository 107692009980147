.page-basket-user .separator {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #1A1D39;
  margin-top: 65px;
  margin-bottom: 31px; }

.page-basket-user .basket-registration-box, .page-basket-user .basket-registration-registration, .page-basket-user .basket-registration-login {
  display: none; }

.page-basket-user .basket-registration-box {
  padding-top: 58px; }

.page-basket-user .basket-registration-parent {
  padding-top: 47px; }
  .page-basket-user .basket-registration-parent.sys {
    display: flex; }

.page-basket-user .basket-registration-texteco {
  font-size: 18px;
  line-height: 1.2222222222;
  margin-top: 50px;
  margin-bottom: 29px; }

.page-basket-user .basket-registration-boxeco {
  font-size: 18px;
  line-height: 1.2222222222;
  background-image: url("../img/bg/page-basket-user-eco.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  box-shadow: 0 0 25px rgba(152, 38, 137, 0.16);
  padding: 39px 61px 55px; }

.page-basket-user .basket-registration-checkboxes .form-checkbox {
  margin-top: 50px; }

.page-basket-user .basket-box-inner {
  cursor: pointer;
  height: 100%;
  text-align: center;
  background-color: #FFFFFF;
  background-image: url("../img/bg/page-basket-user.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
  background-clip: padding-box;
  box-shadow: 0 0 25px rgba(152, 38, 137, 0.16);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding: 30px 60px 37px; }
  .page-basket-user .basket-box-inner:hover {
    box-shadow: 0 0 25px rgba(25, 208, 140, 0.41); }
    .page-basket-user .basket-box-inner:hover .basket-box-title {
      color: #10162B; }
    .page-basket-user .basket-box-inner:hover .basket-box-desc {
      color: #707070; }
  .page-basket-user .basket-box-inner.active {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
    background-color: #19D08C; }
    .page-basket-user .basket-box-inner.active .basket-box-title {
      color: #FFFFFF; }
      .page-basket-user .basket-box-inner.active .basket-box-title:after {
        background-color: #FFFFFF; }
    .page-basket-user .basket-box-inner.active .basket-box-desc {
      color: #FFFFFF; }

.page-basket-user .basket-box-title {
  font-size: 25px;
  font-weight: 600;
  color: rgba(16, 22, 43, 0.25);
  line-height: 1.24;
  text-transform: uppercase;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  margin-bottom: 20px; }
  .page-basket-user .basket-box-title:after {
    content: '';
    display: block;
    width: 74px;
    height: 3px;
    background-color: #19D08C;
    margin: 15px auto 0; }

.page-basket-user .basket-box-desc {
  font-size: 16px;
  font-weight: 600;
  color: rgba(112, 112, 112, 0.26);
  line-height: 1.5;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }

.page-basket-user #basket-registration .btn-regtype {
  width: 100%;
  height: 75px;
  font-size: 20px;
  font-weight: 600;
  color: rgba(16, 22, 43, 0.25);
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  background-image: url("../img/bg/page-basket-user-button.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;
  background-clip: padding-box;
  box-shadow: rgba(233, 50, 145, 0.16);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .page-basket-user #basket-registration .btn-regtype:hover {
    color: #10162B; }
  .page-basket-user #basket-registration .btn-regtype.active {
    color: #FFFFFF;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
    background-color: #19D08C; }

.page-basket-user #basket-login.row {
  margin-left: 0;
  margin-right: 0; }

.page-basket-user #basket-login .btn-gradient {
  max-width: 456px;
  width: 100%;
  margin: 40px auto 0; }

@media screen and (max-width: 660px) {
  .page-basket-user .separator {
    margin-top: 30px;
    margin-bottom: 30px; }
  .page-basket-user .basket-box-inner {
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    background-clip: padding-box;
    padding: 30px 15px; }
  .page-basket-user .basket-box-title {
    font-size: 16px; }
  .page-basket-user .basket-box-desc {
    font-size: 14px;
    line-height: 1.3; }
  .page-basket-user .basket-registration-parent, .page-basket-user .basket-registration-box {
    padding-top: 30px; }
  .page-basket-user .basket-registration-button-right {
    margin-top: 30px; }
  .page-basket-user .basket-registration-boxeco {
    font-size: 15px;
    line-height: 1.2;
    padding: 20px 20px; }
  .page-basket-user .basket-registration-checkboxes .form-checkbox {
    margin-top: 25px; }
  .page-basket-user #basket-registration .btn-regtype {
    font-size: 16px;
    height: 56px; }
  .page-basket-user #basket-login .btn-gradient {
    margin: 20px auto 0; } }

@media screen and (max-width: 575px) {
  .page-basket-user .basket-left {
    padding-bottom: 50px; } }
