.page-basket-hasdomain {
  padding-bottom: 0; }
  .page-basket-hasdomain .has-domain-col {
    max-width: 746px;
    flex-basis: 746px; }
  .page-basket-hasdomain h1, .page-basket-hasdomain .form-search, .page-basket-hasdomain .chk-radio {
    max-width: 100%;
    flex-basis: 100%;
    margin-left: 15px; }
  .page-basket-hasdomain h1 {
    padding-top: 59px;
    margin-bottom: 57px; }
  .page-basket-hasdomain .form-search {
    margin-bottom: 38px; }
  .page-basket-hasdomain .form-input {
    max-width: calc(100% - 250px);
    flex-basis: calc(100% - 250px); }
  .page-basket-hasdomain .chk-radio {
    margin-bottom: 55px; }
  .page-basket-hasdomain:before {
    display: none; }

.basket-left {
  max-width: 746px;
  flex-basis: 746px;
  padding: 59px 15px 0; }
  .basket-left h1 {
    margin-bottom: 68px; }

.basket-right {
  position: sticky;
  top: 130px;
  max-width: 475px;
  flex-basis: 475px;
  align-self: flex-start;
  background-color: #FFFFFF;
  background-image: url("../img/bg/page-basket-right.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(38, 49, 152, 0.31);
  padding: 40px 40px;
  margin-left: auto;
  margin-top: 68px; }
  .basket-right h2 {
    font-size: 30px;
    color: #10162B;
    line-height: 1.2666666667;
    text-transform: uppercase;
    margin-bottom: 28px; }

.basket-help {
  font-size: 20px;
  color: #BABBC3;
  line-height: 1.1;
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 20px; }
  .basket-help a {
    font-weight: 700;
    color: #10162B; }
  .basket-help span {
    display: none; }

.basket-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(38, 49, 152, 0.31);
  padding: 10px;
  margin-top: 24px;
  margin-bottom: 36px; }
  .basket-form-input {
    font-size: 16px;
    font-weight: 600;
    max-width: calc(100% - 240px);
    flex-basis: calc(100% - 240px);
    height: 49px;
    padding-left: 22px; }
  .basket-form-submit {
    font-size: 16px !important;
    font-weight: 500;
    text-transform: uppercase;
    width: unset;
    padding: 5px 18px; }

.basket-link {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  height: 56px;
  background-color: #19D08C;
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
  background-clip: padding-box;
  box-shadow: 0 0 10px #19d08c;
  margin-top: 8px;
  margin-bottom: 21px; }
  .basket-link:hover, .basket-link:focus {
    color: #FFFFFF;
    background-color: #19D08C; }

.basket-clear {
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #D1D1D1;
  line-height: 1.125;
  text-transform: uppercase;
  margin-bottom: 26px; }
  .basket-clear img {
    margin-right: 10px; }

.basket-input.form-control {
  width: 100%;
  height: 37px;
  background-color: transparent;
  border-bottom: 1px solid #D3D3D3;
  padding: 0; }

.basket-input::-webkit-input-placeholder {
  font-weight: 700;
  color: #CFCFCF; }

.basket-items {
  border-top: 1px solid #D3D3D3; }

.basket-item {
  display: flex;
  align-items: center;
  min-height: 97px;
  border-bottom: 1px solid #D3D3D3;
  padding: 15px 0; }
  .basket-item-last {
    border-bottom: none; }
  .basket-item-free .basket-item-title {
    color: #19D08C; }
  .basket-item-title {
    font-size: 20px;
    font-weight: 700;
    color: #10162B;
    line-height: 1.45; }
  .basket-item-subtitle {
    font-size: 18px;
    font-weight: 600;
    color: rgba(96, 96, 96, 0.3);
    line-height: 1.2222222222;
    margin-top: 20px; }
  .basket-item-price-orig, .basket-item-price-sale {
    font-size: 20px;
    font-weight: 700;
    color: #10162B;
    line-height: 1.15; }
  .basket-item-price-vat {
    max-width: 100%;
    flex-basis: 100%;
    font-size: 17px;
    color: #10162B;
    line-height: 1.3529411765; }
  .basket-item-delete {
    cursor: pointer;
    display: block;
    margin-top: 10px; }
  .basket-item-coupon .basket-item-price-orig {
    color: #19D08C; }
  .basket-item-center {
    justify-content: center; }
  .basket-item-left {
    max-width: calc(100% - 140px);
    flex-basis: calc(100% - 140px); }
  .basket-item-right {
    max-width: 140px;
    flex-basis: 140px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: right; }
    .basket-item-right p {
      max-width: 100%;
      flex-basis: 100%; }
    .basket-item-right.has-discount .basket-item-price-orig {
      font-size: 20px;
      line-height: 1.25;
      color: #BABBC3;
      text-decoration: line-through; }

.page-basket-steps {
  padding-top: 40px; }
  .page-basket-steps .step {
    display: inline-block;
    position: relative;
    padding: 0 15px; }
    .page-basket-steps .step-scroll {
      display: flex;
      max-width: 1310px;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      padding: 20px 0;
      margin: 0 auto; }
    .page-basket-steps .step-scroll::-webkit-scrollbar {
      width: 0;
      height: 0; }
    .page-basket-steps .step-inner {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      min-width: 297px;
      height: 75px;
      font-size: 15px;
      color: #10162B;
      line-height: 1.2666666667;
      text-transform: uppercase;
      background-color: #FFFFFF;
      background-image: url("../img/bg/page-basket-step.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: auto;
      -webkit-border-radius: 26px;
      -moz-border-radius: 26px;
      border-radius: 26px;
      background-clip: padding-box;
      border: 1px solid #DCDCDC;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .page-basket-steps .step-inner img {
        margin-left: 15px; }
    .page-basket-steps .step.active .step-inner {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.25;
      border-color: transparent;
      box-shadow: 0 0 20px rgba(152, 38, 137, 0.31); }
    .page-basket-steps .step:after {
      content: '';
      position: absolute;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto;
      z-index: -1; }
    .page-basket-steps .step-01:after {
      top: -31px;
      right: -55px;
      width: 111px;
      height: 107px;
      background-image: url("../img/page-basket-step-01.svg"); }
    .page-basket-steps .step-02:after {
      top: 10px;
      right: -40px;
      width: 65px;
      height: 67px;
      background-image: url("../img/page-basket-step-02.svg"); }
    .page-basket-steps .step-03:after {
      top: -29px;
      right: -90px;
      width: 111px;
      height: 107px;
      background-image: url("../img/page-basket-step-03.svg"); }

@media screen and (max-width: 1366px) {
  .basket-right {
    max-width: 394px;
    flex-basis: 394px;
    padding: 40px 20px; } }

@media screen and (max-width: 1199px) {
  .page-basket-hasdomain .has-domain-col, .basket-left, .basket-right {
    max-width: 100%;
    flex-basis: 100%; }
  .basket-right {
    margin-left: 15px;
    margin-right: 15px; } }

@media screen and (max-width: 768px) {
  .page-basket-steps .step {
    padding: 0 9px; }
    .page-basket-steps .step-01:after, .page-basket-steps .step-02:after, .page-basket-steps .step-03:after {
      top: -40px;
      right: -69px;
      width: 111px;
      height: 107px;
      background-image: url("../img/page-basket-step-01.svg"); }
    .page-basket-steps .step-inner {
      min-width: 223px;
      height: 54px;
      font-size: 14px;
      line-height: 1.2857142857; }
      .page-basket-steps .step-inner img {
        width: 20px;
        height: auto; }
    .page-basket-steps .step.active .step-inner {
      font-size: 14px;
      line-height: 1.2857142857; }
  .wwh-card-skeleton .rect {
    margin: 0 auto; }
  .wwh-card-skeleton .wwh-card-title span {
    width: 10px;
    height: 49px; }
  .wwh-card-skeleton .wwh-card-content {
    padding: 26px 15px; } }

@media screen and (max-width: 660px) {
  .page-basket-hasdomain h1 {
    padding-top: 30px;
    margin-bottom: 30px; }
  .page-basket-hasdomain .form-input {
    max-width: calc(100% - 175px);
    flex-basis: calc(100% - 175px); } }

@media screen and (max-width: 575px) {
  .page-basket-steps {
    padding-top: 0; }
  .basket-left {
    padding: 20px 15px 0; }
    .basket-left h1 {
      text-align: center;
      margin-bottom: 30px; }
  .basket-right {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box;
    margin-top: 10px; }
  .basket-help span {
    display: block;
    width: 100%; }
  .basket-form {
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    background-clip: padding-box; }
    .basket-form-input {
      max-width: 100%;
      flex-basis: 100%; }
    .basket-form-submit {
      max-width: 100%;
      flex-basis: 100%;
      justify-content: center; } }
