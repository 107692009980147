.accordion-inner {
  position: relative;
  background-color: #FFFFFF;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box;
  background-image: url("../img/bg/domain1-faq.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
  padding: 0 80px 0 15px; }
  .accordion-inner.active .accordion-arrow {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.accordion-arrow {
  cursor: pointer;
  display: block;
  position: absolute;
  bottom: 40px;
  right: 42px;
  width: 28px;
  height: 17px;
  background-image: url("../img/icons/arrow-down-blue-big.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -ms-transform-origin: center center;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center; }

.accordion-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100px; }
  .accordion-header p {
    font-size: 20px;
    font-weight: 600;
    color: #10162B;
    text-transform: uppercase; }

.accordion-circle {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  min-width: 63px;
  height: 63px;
  background-color: #FFFFFF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  box-shadow: 0 0 22px rgba(242, 29, 164, 0.28);
  margin-right: 14px; }

.accordion-content {
  display: none;
  padding: 0 35px 40px; }
  .accordion-content p, .accordion-content ul {
    font-size: 18px;
    color: #606060; }
  .accordion-content p {
    line-height: 1.2777777778;
    margin-bottom: 15px; }
  .accordion-content ul {
    margin-bottom: 40px; }

@media screen and (max-width: 767px) {
  .accordion-content {
    padding: 0 15px 15px; } }

@media screen and (max-width: 575px) {
  .accordion-inner {
    padding: 0 55px 0 0; }
  .accordion-header {
    height: unset;
    min-height: 107px;
    padding-top: 5px;
    padding-right: 0;
    padding-bottom: 5px;
    padding-left: 46px; }
    .accordion-header p {
      line-height: 1.25; }
  .accordion-arrow {
    right: 18px;
    width: 22px;
    height: 24px;
    background-size: contain; }
  .accordion-circle {
    position: absolute;
    top: -16px;
    left: -10px;
    width: 50px;
    min-width: 50px;
    height: 50px; } }
