.page-contact {
  padding-top: 114px;
  padding-bottom: 70px; }
  .page-contact h1 {
    margin-bottom: 52px; }
  .page-contact .reason {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 550px;
    min-height: 100px;
    background-image: url("../img/bg/main-card-02.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #FFFFFF;
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box;
    padding: 18px 11px;
    margin-bottom: 21px; }
    .page-contact .reason-icon {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      max-width: 63px;
      flex-basis: 63px;
      min-width: 63px;
      height: 63px;
      background-color: #FFFFFF;
      box-shadow: 0 0 22px rgba(242, 29, 165, 0.28);
      margin-right: 14px; }
    .page-contact .reason-content h3 {
      font-size: 20px;
      color: #E83192;
      line-height: 1.1;
      text-transform: uppercase;
      margin-bottom: 3px; }
    .page-contact .reason-content p {
      font-size: 18px;
      line-height: 1.2222222222;
      color: #606060; }
  .page-contact .form {
    padding-top: 7.78vw; }
    .page-contact .form h2 {
      margin-bottom: 50px; }
    .page-contact .form-textarea {
      height: 186px; }
    .page-contact .form-checkbox {
      margin-top: 39px;
      margin-bottom: 48px; }
    .page-contact .form-submit {
      width: 100%;
      margin-left: 0; }
  .page-contact:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 1001px;
    width: 50vw;
    height: 1169px;
    background-image: url("../img/desktop/contact.png");
    background-position: right 0;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

@media screen and (max-width: 1024px) {
  .page-contact h1 {
    padding-top: 21vw;
    margin-bottom: 50px; }
  .page-contact h2 {
    margin-bottom: 30px; }
  .page-contact .reasons {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
    margin-left: -15px;
    margin-right: -15px; }
  .page-contact .reason {
    max-width: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
    margin: 0 15px 30px; }
    .page-contact .reason:first-child {
      margin-right: 50%; }
  .page-contact .form {
    padding-top: 30px; }
    .page-contact .form-control {
      width: 100%;
      text-align: center; }
    .page-contact .form-checkbox {
      margin-top: 46px;
      margin-bottom: 27px; }
    .page-contact .form-submit {
      margin: 0 auto; }
  .page-contact .chk-radio {
    text-align: center;
    padding-left: 0;
    padding-top: 46px; }
    .page-contact .chk-radio .checkmark {
      left: 50%;
      background-color: #FFFFFF;
      -ms-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%); }
  .page-contact:before {
    max-width: unset;
    width: 100%;
    height: 675px;
    background-image: url("../img/tablet/page-contact.png"); } }

@media screen and (max-width: 660px) {
  .page-contact {
    padding-top: 0;
    padding-bottom: 0; }
    .page-contact h1 {
      text-align: center;
      padding-top: 0; }
    .page-contact h2 {
      text-align: center; }
    .page-contact .reason {
      max-width: calc(100% - 30px);
      flex-basis: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 21px; }
      .page-contact .reason:first-child {
        margin-right: auto; }
    .page-contact .mobil-picture {
      width: auto;
      margin-left: auto; }
    .page-contact:before {
      display: none; } }
