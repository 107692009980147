.table-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.table-col .old {
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: #BABBC3;
  text-decoration: line-through; }

.table-col .new {
  font-size: 18px;
  font-weight: 600;
  color: #19D08C; }

.table-header {
  position: sticky;
  top: 98px;
  height: 78px;
  font-size: 18px;
  color: #FFFFFF;
  background-color: #10162B;
  -webkit-border-radius: 44px 44px 0 0;
  -moz-border-radius: 44px 44px 0 0;
  border-radius: 44px 44px 0 0;
  background-clip: padding-box;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  z-index: 999; }
  .table-header.isSticky {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-clip: padding-box; }

.table-item {
  height: 125px;
  border-bottom: 1px solid #CBCBCB; }
