.page-error {
  padding: 68px 0; }
  .page-error .error-img, .page-error .form-search {
    margin: 0 auto; }
  .page-error .error-img {
    margin-bottom: 60px; }
  .page-error .form-search {
    margin-bottom: 15px; }
  .page-error p {
    font-size: 16px;
    color: #707070;
    line-height: 1.8125; }
