.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("../img/empty.gif");
  background-position: center center;
  background-repeat: repeat;
  background-size: auto;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .backdrop.sys {
    display: block; }

.section-subtitle {
  font-size: 20px;
  font-weight: 400;
  color: #707070;
  line-height: 1.45; }
  .section-subtitle-bold {
    font-weight: 500; }

.text-center {
  text-align: center; }

.text-uppercase {
  text-transform: uppercase; }

.img-resp {
  max-width: 100%;
  height: auto; }

.img-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; }

ul {
  list-style-type: none; }
  ul.ul {
    list-style-position: outside;
    list-style-type: none; }
    ul.ul li {
      position: relative;
      display: list-item;
      padding-left: 20px; }
      ul.ul li:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 0;
        width: 12px;
        height: 12px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-clip: padding-box;
        background-color: #E83192; }

.after-line {
  position: relative; }
  .after-line:after {
    content: '';
    display: block;
    width: 82px;
    height: 3px;
    background-color: #EE3194; }

.after-line-mobil-center:after, .after-line-mobil:after, .after-line-tablet-center:after, .after-line-tablet:after {
  content: '';
  display: none;
  width: 77px;
  height: 5px;
  background-color: #FF2C9C;
  margin-top: 9px; }

.after-line-mobil-center:after, .after-line-tablet-center:after {
  margin: 9px auto 0; }

.mobil-picture, .tablet-picture {
  display: none;
  width: 100%; }

.action-bar {
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: none;
  background-color: #F5F5F5;
  padding: 13px 0;
  margin-bottom: 0; }
  .action-bar .row {
    align-items: center; }
    .action-bar .row .left a {
      display: inline-block;
      margin-right: 15px; }
      .action-bar .row .left a img {
        vertical-align: middle; }
    .action-bar .row .right a {
      max-width: 214px;
      width: 100%; }

@media screen and (max-width: 1199px) {
  ul.ul li {
    padding-left: 18px; }
    ul.ul li:before {
      width: 12px;
      height: 12px; } }

@media screen and (max-width: 1024px) {
  .action-bar {
    display: block; }
  .after-line-tablet:after {
    display: block; }
  .tablet-picture {
    display: block; } }

@media screen and (max-width: 660px) {
  .action-bar {
    padding: 6.5px 0; }
    .action-bar .row .left {
      display: flex;
      justify-content: space-between; }
      .action-bar .row .left a {
        display: inline-flex;
        margin: 0; }
      .action-bar .row .left img {
        width: auto;
        height: 18px; }
    .action-bar .row .right a {
      max-width: 111px;
      height: 37px;
      font-size: 15px;
      padding: 5px 15px; }
      .action-bar .row .right a img {
        width: 16px;
        height: auto; }
  .after-line-mobil-center:after, .after-line-mobil:after, .after-line-tablet-center:after, .after-line-tablet:after {
    display: block;
    height: 2px;
    margin-top: 15px; }
  .after-line-mobil:after, .after-line-mobil-center:after {
    display: block; }
  .mobil-picture {
    display: block; } }
