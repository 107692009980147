.has-domain {
  position: relative;
  padding-bottom: 16vw; }
  .has-domain-col {
    max-width: 1060px;
    width: 100%;
    padding: 0 15px; }
    .has-domain-col h1, .has-domain-col h2, .has-domain-col form {
      max-width: 50%;
      flex-basis: 50%; }
  .has-domain h1 {
    padding-top: 12.88vw;
    margin-bottom: 19px; }
  .has-domain h2 {
    margin-bottom: 34px; }
  .has-domain:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 875px;
    width: 50vw;
    height: 1169px;
    background-image: url("../img/desktop/wwh-domain.png");
    background-position: right -94px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.why-wwh {
  position: relative; }
  .why-wwh h2 {
    margin-bottom: 53px; }
  .why-wwh .swiper-wrapper {
    max-width: 1310px;
    justify-content: space-between;
    margin: 0 auto 38px; }
  .why-wwh .swiper-button-next,
  .why-wwh .swiper-button-prev {
    display: none; }
  .why-wwh .card-item {
    max-width: 402px;
    flex-basis: 402px;
    padding: 30px; }
    .why-wwh .card-item:nth-child(2) {
      position: relative; }
      .why-wwh .card-item:nth-child(2):before, .why-wwh .card-item:nth-child(2):after {
        content: '';
        position: absolute;
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto;
        z-index: -1; }
      .why-wwh .card-item:nth-child(2):before {
        top: 199px;
        left: -104px;
        width: 161px;
        height: 193px;
        background-image: url("../img/main-why-wwh-rope-01.svg"); }
      .why-wwh .card-item:nth-child(2):after {
        top: 120px;
        right: -124px;
        width: 195px;
        height: 178px;
        background-image: url("../img/main-why-wwh-rope-02.svg"); }
  .why-wwh .card-inner {
    background-color: #FFFFFF;
    -webkit-border-radius: 44px;
    -moz-border-radius: 44px;
    border-radius: 44px;
    background-clip: padding-box;
    box-shadow: 0 0 36px rgba(152, 38, 137, 0.31); }
  .why-wwh .card-header {
    width: 100%;
    height: 171px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain; }
  .why-wwh .card-content {
    background-image: url("../img/bg/main-card-01.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 0 15px 54px; }
  .why-wwh .card-icon {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 125px;
    height: 125px;
    background-color: #FFFFFF;
    box-shadow: 0 0 20px rgba(243, 36, 209, 0.16);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 auto; }
  .why-wwh .card-title {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2571428571;
    text-transform: uppercase;
    margin-top: -49px;
    margin-bottom: 17px; }
  .why-wwh .card-desc {
    font-size: 20px;
    color: #606060;
    line-height: 1.25;
    margin-bottom: 14px; }
    .why-wwh .card-desc:after {
      content: '';
      display: block;
      width: 80px;
      height: 3px;
      background-color: #EE3194;
      margin: 15px auto 0; }
  .why-wwh .card-info {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 37px; }
    .why-wwh .card-info-magenta {
      color: #EE3194; }
    .why-wwh .card-info-blue {
      color: #3C1E71; }
    .why-wwh .card-info-blue2 {
      color: #1A1D39; }
  .why-wwh .card-line {
    width: 28px;
    height: 2px;
    margin-top: 12px; }
    .why-wwh .card-line-purple {
      background-color: #461E6C; }
    .why-wwh .card-line-purple2 {
      background-color: #B929C1; }
    .why-wwh .card-line-magenta {
      background-color: #E93092; }
  .why-wwh .reason {
    display: flex;
    align-items: center;
    width: 100%;
    background-image: url("../img/bg/main-card-02.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #FFFFFF;
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box;
    min-height: 100px;
    padding: 18px 11px;
    margin-bottom: 21px; }
    .why-wwh .reason-icon {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      max-width: 63px;
      flex-basis: 63px;
      min-width: 63px;
      height: 63px;
      background-color: #FFFFFF;
      box-shadow: 0 0 22px rgba(242, 29, 165, 0.28);
      margin-right: 14px; }
    .why-wwh .reason-content h3 {
      font-size: 20px;
      color: #E83192;
      line-height: 1.1;
      text-transform: uppercase;
      margin-bottom: 3px; }
    .why-wwh .reason-content p {
      font-size: 18px;
      line-height: 1.2222222222;
      color: #606060; }
  .why-wwh:before {
    content: '';
    display: block;
    position: absolute;
    top: 371px;
    left: 0;
    max-width: 956px;
    width: 50vw;
    height: 1216px;
    background-image: url("../img/desktop/why-wwh.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.wwh-in-numbers {
  position: relative;
  padding-bottom: 11.5vw; }
  .wwh-in-numbers h2 {
    padding-top: 10.5vw;
    margin-bottom: 74px; }
  .wwh-in-numbers .card-inner {
    height: 100%;
    background-image: url("../img/bg/main-card-03.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #FFFFFF;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    background-clip: padding-box;
    box-shadow: 0 0 20px rgba(152, 28, 137, 0.31);
    padding: 56px 15px 75px; }
  .wwh-in-numbers .card-icon {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    width: 63px;
    height: 63px;
    background-color: #FFFFFF;
    box-shadow: 0 0 22px rgba(242, 29, 165, 0.28);
    margin: 0 auto 15px; }
  .wwh-in-numbers .card-counter {
    font-size: 30px;
    color: #10162B; }
    .wwh-in-numbers .card-counter, .wwh-in-numbers .card-counter span {
      font-weight: 700;
      line-height: 1; }
  .wwh-in-numbers .card-title {
    font-size: 18px;
    color: #606060;
    line-height: 1.2777777778; }
  .wwh-in-numbers:before {
    content: '';
    display: block;
    position: absolute;
    top: -341px;
    right: 0;
    max-width: 1008px;
    width: 50vw;
    height: 1385px;
    background-image: url("../img/desktop/wwh-in-numbers.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.technologies {
  background-color: #FFFFFF;
  padding-bottom: 40px; }
  .technologies .row {
    align-items: center;
    justify-content: center; }
  .technologies img {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
    height: auto;
    filter: grayscale(100%);
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    padding: 0 15px; }
    .technologies img:hover {
      filter: grayscale(0); }

.packages h2 {
  margin-bottom: 55px; }

@media screen and (max-width: 1366px) {
  .why-wwh .card-item {
    max-width: 31%;
    flex-basis: 31%; }
  .why-wwh .card-header {
    height: 140px; }
  .why-wwh .card-icon {
    width: 100px;
    height: 100px; }
    .why-wwh .card-icon img {
      width: 45px;
      height: auto; }
  .why-wwh .card-title {
    margin-top: -40px; } }

@media screen and (max-width: 1199px) {
  .why-wwh .card-header {
    height: 100px; }
  .why-wwh .card-icon {
    width: 80px;
    height: 80px; }
    .why-wwh .card-icon img {
      width: 30px;
      height: auto; } }

@media screen and (max-width: 1024px) {
  .has-domain-col h1, .has-domain-col h2, .has-domain-col form {
    max-width: 390px;
    flex-basis: 390px; }
  .has-domain h1 {
    padding-top: 44vw;
    margin-bottom: 21px; }
  .has-domain h2 {
    margin-bottom: 45px; }
  .has-domain:before {
    max-width: 991px;
    width: 100vw;
    height: 971px;
    background-image: url("../img/tablet/wwh-domain.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain; }
  .why-wwh .row-slider .col-12 {
    padding-left: 0;
    padding-right: 0; }
  .why-wwh .swiper-wrapper {
    display: flex;
    justify-content: space-between; }
  .why-wwh .card-item {
    max-width: 210px;
    flex-basis: 210px;
    padding: 30px 0; }
    .why-wwh .card-item:nth-child(2) {
      position: relative; }
      .why-wwh .card-item:nth-child(2):before, .why-wwh .card-item:nth-child(2):after {
        background-size: contain; }
      .why-wwh .card-item:nth-child(2):before {
        top: 140px;
        left: -44px;
        width: 85px;
        height: 106px; }
      .why-wwh .card-item:nth-child(2):after {
        top: 90px;
        right: -74px;
        width: 107px;
        height: 98px; }
  .why-wwh .card-header {
    height: 90px;
    background-size: cover; }
  .why-wwh .card-icon {
    width: 70px;
    height: 70px; }
  .why-wwh .card-content {
    padding: 0 15px 24px; }
  .why-wwh .card-title {
    font-size: 25px; }
  .why-wwh .card-desc {
    font-size: 16px;
    line-height: 1.25; }
  .why-wwh .card-info {
    font-size: 17px;
    line-height: 1.2941176471; }
  .why-wwh .card-button {
    max-width: 154px;
    height: 39px; }
  .why-wwh .reason-icon {
    max-width: 56px;
    flex-basis: 56px;
    min-width: 56px;
    height: 56px; }
    .why-wwh .reason-icon img {
      width: 25px;
      height: auto; }
  .why-wwh .reason-content h3 {
    font-size: 18px;
    line-height: 1.2222222222; }
  .why-wwh .reason-content p {
    font-size: 16px;
    line-height: 1.25; }
  .why-wwh:before {
    top: 510px;
    max-width: 421px;
    width: 100vw;
    height: 785px;
    background-image: url("../img/tablet/why-wwh.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }
  .wwh-in-numbers {
    padding-bottom: 84px; }
    .wwh-in-numbers h2 {
      text-align: center;
      padding-top: 39px;
      margin-bottom: 42px; }
    .wwh-in-numbers .card {
      max-width: 210px;
      flex-basis: 210px;
      margin: 0 auto; }
      .wwh-in-numbers .card-inner {
        padding-top: 41px;
        padding-bottom: 47px; }
      .wwh-in-numbers .card-icon {
        width: 99px;
        height: 99px;
        margin: 0 auto 24px; }
        .wwh-in-numbers .card-icon img {
          width: 45px;
          height: auto; }
    .wwh-in-numbers:before {
      display: none; }
  .packages h2 {
    text-align: center;
    margin-bottom: 31px; }
  .technologies img {
    max-width: 33.33333%;
    flex-basis: 33.33333%; } }

@media screen and (max-width: 660px) {
  .has-domain h1 {
    text-align: center;
    padding-top: calc(108vw + 8px);
    margin-bottom: 16px; }
  .has-domain h2 {
    font-size: 14px;
    line-height: 1.3571428571; }
  .has-domain form {
    margin-bottom: 50px; }
  .has-domain-col h1, .has-domain-col h2, .has-domain-col form {
    max-width: 100%;
    flex-basis: 100%; }
  .has-domain:before {
    top: 0;
    max-width: 660px;
    height: 749px;
    background-image: url("../img/mobil/wwh-domain.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain; }
  .why-wwh .swiper-button-next,
  .why-wwh .swiper-button-prev {
    display: flex; }
  .why-wwh .card-item {
    max-width: 250px;
    flex-basis: 250px; }
    .why-wwh .card-item.swiper-slide-active {
      z-index: 100000 !important; }
    .why-wwh .card-item:nth-child(2):before, .why-wwh .card-item:nth-child(2):after {
      display: none; }
  .why-wwh .card-header {
    height: 58px; }
  .why-wwh .card-line {
    display: none; }
  .why-wwh .card-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  .why-wwh .card-title {
    max-width: 100%;
    flex-basis: 100%;
    font-size: 33px;
    margin-top: -35px;
    order: 1; }
  .why-wwh .card-desc {
    max-width: 100%;
    flex-basis: 100%;
    font-size: 14px;
    line-height: 1.3571428571;
    order: 3; }
    .why-wwh .card-desc:after {
      display: none; }
  .why-wwh .card-info {
    max-width: 100%;
    flex-basis: 100%;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 5px;
    order: 2; }
    .why-wwh .card-info:after {
      content: '';
      display: block;
      width: 80px;
      height: 2px;
      background-color: #EE3194;
      margin: 5px auto 0; }
  .why-wwh .card-button {
    order: 4; }
  .why-wwh h2 {
    text-align: center;
    padding-top: 33px;
    margin-bottom: 30px; }
  .why-wwh .reason {
    padding: 10px 8px;
    min-height: 75px;
    margin-bottom: 14px; }
    .why-wwh .reason-icon {
      max-width: 48px;
      flex-basis: 48px;
      min-width: 48px;
      height: 48px; }
      .why-wwh .reason-icon img {
        width: 21px; }
    .why-wwh .reason-content h3 {
      font-weight: 600; }
    .why-wwh .reason-content h3, .why-wwh .reason-content p {
      font-size: 13px;
      line-height: 1.2307692308; }
  .why-wwh:before {
    display: none; }
  .wwh-in-numbers .mobil-picture {
    max-width: calc(100% - 15px);
    height: auto;
    margin-right: 0;
    margin-left: auto; }
  .wwh-in-numbers h2 {
    padding-top: 19px; }
  .wwh-in-numbers .card {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
    .wwh-in-numbers .card-inner {
      padding: 31px 15px 28px 15px; }
    .wwh-in-numbers .card-icon {
      width: 63px;
      height: 63px;
      background-color: #1A1D39;
      margin: 0 auto 9px; }
      .wwh-in-numbers .card-icon img {
        width: 29px; }
    .wwh-in-numbers .card-title {
      font-size: 15px;
      color: #10162B;
      line-height: 1.5333333333;
      min-height: 46px; }
  .technologies {
    padding-bottom: 30px; }
    .technologies img {
      max-width: 35%;
      flex-basis: 35%; } }

@media screen and (max-width: 475px) {
  .wwh-in-numbers .card {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 10px; }
    .wwh-in-numbers .card-inner {
      padding-top: 15px;
      padding-bottom: 15px; }
    .wwh-in-numbers .card-icon {
      width: 50px;
      height: 50px; }
      .wwh-in-numbers .card-icon img {
        width: 24px; }
    .wwh-in-numbers .card-title {
      min-height: unset; } }
