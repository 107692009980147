@charset "UTF-8";
.page-vps {
  position: relative;
  padding-top: 17.5vw;
  padding-bottom: 200px; }
  .page-vps h1 {
    margin-bottom: 22px; }
  .page-vps h2 {
    margin-bottom: 20px; }
  .page-vps:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 988px;
    width: 50vw;
    height: 1169px;
    background-image: url("../img/desktop/vps.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.page-vpsmanaged {
  position: relative;
  padding-top: 14.5vw; }
  .page-vpsmanaged h2 {
    margin-bottom: 31px; }
  .page-vpsmanaged .lead {
    font-size: 20px;
    color: #707070;
    line-height: 1.75;
    margin-bottom: 35px; }
  .page-vpsmanaged .box {
    background-image: url("../img/bg/vps.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box;
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    padding: 28px 22px 57px;
    margin-top: -10px; }
    .page-vpsmanaged .box p {
      font-size: 17px;
      font-weight: 700;
      color: #10162B;
      line-height: 1.2352941176; }
      .page-vpsmanaged .box p:not(:last-child) {
        margin-bottom: 21px; }
  .page-vpsmanaged:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 762px;
    width: 50vw;
    height: 1094px;
    background-image: url("../img/desktop/vps2.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.page-vpspackages {
  padding-top: 91px; }
  .page-vpspackages .sl-package-vattext {
    font-size: 20px;
    color: #707070;
    line-height: 1.45; }
    .page-vpspackages .sl-package-vattext:not(:last-child) {
      margin-bottom: 29px; }

.page-vpsdetails {
  padding-top: 65px; }
  .page-vpsdetails h2 {
    margin-bottom: 48px; }
  .page-vpsdetails .table-header {
    position: sticky;
    top: 98px; }
    .page-vpsdetails .table-header .table-col-3 {
      color: #FFFFFF; }
  .page-vpsdetails .table-item {
    position: relative;
    height: 110px; }
    .page-vpsdetails .table-item-last {
      border-bottom: none; }
    .page-vpsdetails .table-item .table-col-1 {
      font-size: 23px;
      line-height: 1.3043478261; }
  .page-vpsdetails .table-col {
    padding: 0 40px; }
    .page-vpsdetails .table-col-1, .page-vpsdetails .table-col-2 {
      max-width: 25%;
      flex-basis: 25%; }
    .page-vpsdetails .table-col-3 {
      max-width: 50%;
      flex-basis: 50%; }
    .page-vpsdetails .table-col-2 p {
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: 500;
      color: #BABBC3; }
      .page-vpsdetails .table-col-2 p img {
        margin-right: 5px; }
    .page-vpsdetails .table-col-3 {
      font-size: 18px;
      font-weight: 500;
      color: #606060;
      line-height: 1.2777777778; }

.page-vpsfees {
  padding-top: 97px;
  padding-bottom: 70px; }
  .page-vpsfees h2 {
    margin-bottom: 26px; }
  .page-vpsfees .lead {
    font-size: 20px;
    color: #707070;
    line-height: 1.45;
    margin-bottom: 48px; }
  .page-vpsfees .row2 h2 {
    padding-top: 62px;
    margin-bottom: 69px; }
  .page-vpsfees .card {
    margin-bottom: 40px; }
    .page-vpsfees .card-inner {
      height: 100%;
      background-image: url("../img/bg/vps2.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: auto;
      -webkit-border-radius: 44px;
      -moz-border-radius: 44px;
      border-radius: 44px;
      background-clip: padding-box;
      box-shadow: 0 0 36px rgba(152, 38, 137, 0.31);
      padding: 61px 44px 51px; }
    .page-vpsfees .card-title {
      display: flex;
      align-items: center;
      font-size: 40px;
      font-weight: 700;
      color: #10162B;
      line-height: 1.15;
      text-transform: uppercase;
      margin-bottom: -6px; }
      .page-vpsfees .card-title span {
        font-size: 40px;
        font-weight: 700;
        color: #EE3797;
        line-height: 1;
        -ms-transform: translateY(-3px);
        -webkit-transform: translateY(-3px);
        -moz-transform: translateY(-3px);
        -o-transform: translateY(-3px);
        transform: translateY(-3px);
        margin-right: 5px; }
    .page-vpsfees .card-titleinfo {
      font-size: 30px;
      color: #707070;
      line-height: 1.7;
      margin-bottom: 34px; }
      .page-vpsfees .card-titleinfo:after {
        content: '';
        display: block;
        width: 80px;
        height: 3px;
        background-color: #EE3797;
        margin-top: 16px; }
    .page-vpsfees .card-price {
      font-size: 40px;
      font-weight: 700;
      color: #10162B;
      line-height: 1;
      margin-bottom: 4px; }
    .page-vpsfees .card-vat {
      font-size: 29px;
      color: #10162B;
      line-height: 1.3448275862; }

@media screen and (max-width: 1024px) {
  .page-vps {
    padding-bottom: 0; }
    .page-vps h1 {
      padding-top: 40vw;
      margin-bottom: 38px; }
    .page-vps h2 {
      line-height: 1.1;
      margin-bottom: 22px; }
      .page-vps h2 br {
        display: none; }
    .page-vps h3 {
      line-height: 1.1;
      margin-bottom: 90px; }
    .page-vps:before {
      max-width: 991px;
      width: 100vw;
      height: 973px;
      background-image: url("../img/tablet/page-vps.png"); }
  .page-vpsmanaged {
    padding-top: 50px; }
    .page-vpsmanaged h2 {
      text-align: center; }
    .page-vpsmanaged .lead {
      text-align: center; }
    .page-vpsmanaged .box {
      position: relative;
      background: #FFFFFF;
      margin-bottom: -157px;
      z-index: 1; }
      .page-vpsmanaged .box p {
        text-align: center; }
    .page-vpsmanaged .tablet-picture-top {
      margin-bottom: 50px; }
    .page-vpsmanaged .col-6-offset {
      margin-left: 0; }
    .page-vpsmanaged:before {
      display: none; }
  .page-vpsdetails .table-header {
    display: none; }
  .page-vpsdetails .table-item {
    min-height: 88px;
    height: auto; }
  .page-vpsdetails .table-col-1 {
    max-width: calc(100% - 185px);
    flex-basis: calc(100% - 185px); }
  .page-vpsdetails .table-col-2 {
    max-width: 185px;
    flex-basis: 185px; }
  .page-vpsdetails .table-col-3 {
    max-width: 100%;
    flex-basis: 100%;
    padding-bottom: 15px; }
  .page-vpsfees {
    padding-top: 50px;
    padding-bottom: 20px; }
    .page-vpsfees h2 {
      margin-bottom: 43px; }
    .page-vpsfees .card {
      margin-bottom: 49px; }
      .page-vpsfees .card-inner {
        text-align: center;
        padding: 55px 15px 46px; }
      .page-vpsfees .card-title {
        justify-content: center;
        font-size: 35px;
        line-height: 1.1428571429; }
      .page-vpsfees .card-titleinfo {
        font-size: 27px;
        line-height: 1.7037037037;
        margin-bottom: 29px; }
        .page-vpsfees .card-titleinfo:after {
          margin: 15px auto 0; }
      .page-vpsfees .card-price {
        font-size: 36px; }
      .page-vpsfees .card-vat {
        font-size: 26px;
        line-height: 1.3461538462; }
    .page-vpsfees .row2 h2 {
      padding-top: 33px;
      margin-bottom: 80px; } }

@media screen and (max-width: 660px) {
  .page-vps {
    padding-top: 0; }
    .page-vps h1 {
      padding-top: 0;
      margin-top: -36px;
      margin-bottom: 16px; }
    .page-vps h2 {
      font-size: 14px;
      line-height: 1.3571428571;
      margin-bottom: 1px; }
    .page-vps h3 {
      font-size: 14px;
      line-height: 1.3571428571;
      margin-bottom: 0; }
    .page-vps .col-md-8 {
      max-width: 100%;
      flex-basis: 100%;
      text-align: center; }
    .page-vps .mobil-picture {
      width: 450px;
      margin-left: auto; }
    .page-vps:before {
      display: none; }
  .page-vpsmanaged {
    padding-top: 0; }
    .page-vpsmanaged h2 {
      margin-bottom: 34px; }
    .page-vpsmanaged .lead {
      font-size: 15px;
      line-height: 1.6666666667;
      text-align: center;
      margin-bottom: 25px; }
    .page-vpsmanaged .box {
      padding: 18px 15px 59px;
      margin-top: 30px;
      margin-bottom: -72px; }
      .page-vpsmanaged .box p {
        font-size: 15px;
        line-height: 1.2666666667;
        text-align: center; }
        .page-vpsmanaged .box p:not(:last-child) {
          margin-bottom: 19px; }
    .page-vpsmanaged .tablet-picture–top {
      margin-bottom: 5px; }
  .page-vpspackages {
    padding-top: 31px; }
    .page-vpspackages h2 {
      margin-bottom: 11px; }
    .page-vpspackages .sl-package-vattext {
      font-size: 15px;
      line-height: 1.6666666667; }
      .page-vpspackages .sl-package-vattext:not(:last-child) {
        margin-bottom: 25px; }
      .page-vpspackages .sl-package-vattext:not(:first-child) {
        font-weight: 600; }
  .page-vpsdetails {
    padding-top: 36px; }
    .page-vpsdetails h2 {
      margin-bottom: 37px; }
    .page-vpsdetails .table-item {
      min-height: auto;
      padding-top: 15px; }
      .page-vpsdetails .table-item .table-col-1, .page-vpsdetails .table-item .table-col-2 {
        max-width: 100%;
        flex-basis: 100%;
        text-align: center; }
      .page-vpsdetails .table-item .table-col-1 {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 20px; }
      .page-vpsdetails .table-item .table-col-2 {
        margin-bottom: 20px; }
        .page-vpsdetails .table-item .table-col-2 p {
          justify-content: center; }
      .page-vpsdetails .table-item .table-col-3 {
        text-align: center; }
  .page-vpsfees {
    padding-top: 44px; }
    .page-vpsfees h2 {
      margin-bottom: 22px; }
    .page-vpsfees .lead {
      font-size: 15px;
      line-height: 1.9333333333;
      margin-bottom: 32px; }
      .page-vpsfees .lead br {
        display: none; }
    .page-vpsfees .card {
      margin-bottom: 20px; }
      .page-vpsfees .card-inner {
        text-align: center;
        -webkit-border-radius: 27px;
        -moz-border-radius: 27px;
        border-radius: 27px;
        background-clip: padding-box;
        padding: 20px 15px; }
      .page-vpsfees .card-title {
        hyphens: auto;
        justify-content: center;
        font-size: 20px;
        line-height: 1.25;
        margin-bottom: 12px; }
      .page-vpsfees .card-titleinfo {
        font-size: 15px;
        line-height: 1.8;
        margin-bottom: 20px; }
        .page-vpsfees .card-titleinfo:after {
          height: 1px;
          margin: 10px auto 0; }
      .page-vpsfees .card-price {
        font-size: 27px;
        margin-bottom: 1px; }
      .page-vpsfees .card-vat {
        font-size: 15px;
        line-height: 1.3333333333; }
    .page-vpsfees .row2 h2 {
      padding-top: 28px;
      margin-bottom: 33px; } }
