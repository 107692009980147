.notification {
  position: fixed;
  display: flex;
  top: 40px;
  right: 40px;
  max-width: 350px;
  width: 100%;
  min-height: 100px;
  background-color: #FFFFFF;
  background-image: url("../img/bg/notification.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(152, 38, 137, 0.31);
  opacity: 0;
  visibility: hidden;
  -ms-transform: translateX(150%);
  -webkit-transform: translateX(150%);
  -moz-transform: translateX(150%);
  -o-transform: translateX(150%);
  transform: translateX(150%);
  -moz-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  -webkit-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
  padding: 18px 10px;
  margin-bottom: 20px;
  z-index: 1001; }
  .notification.sys {
    opacity: 1;
    visibility: visible;
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  .notification-left {
    max-width: 58px;
    flex-basis: 58px; }
  .notification-right {
    max-width: calc(100% - 58px);
    flex-basis: calc(100% - 58px);
    padding-left: 15px; }
    .notification-right p {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.25;
      text-transform: uppercase;
      margin-bottom: 8px; }
      .notification-right p.hys {
        display: none; }
    .notification-right span {
      font-size: 15px;
      color: #BABBC3;
      line-height: 1.4666666667; }
    .notification-right.center {
      align-self: center; }
  .notification-circle {
    width: 56px;
    height: 56px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box;
    box-shadow: 0 0 15px rgba(26, 29, 57, 0.53); }
  .notification-error .notification-circle {
    background: url("../img/icons/warning-filled.svg") no-repeat center calc(50% - 3px), linear-gradient(128deg, #ef3295 0%, #c624ff 200%); }
  .notification-error .notification-right p {
    color: #EC3393; }
  .notification-success .notification-circle {
    background-color: #19D08C;
    background-image: url("../img/icons/check-white-alt.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto; }
  .notification-success .notification-right p {
    color: #19D08C; }
