.knowledge-sub {
  padding-bottom: 41px; }
  .knowledge-sub:before {
    max-width: 919px;
    height: 935px;
    background-image: url("../img/desktop/knowledge-sub.png"); }

.page-content {
  padding-top: 50px;
  padding-bottom: 50px; }
  .page-content .knowledge-sub-title {
    margin-bottom: 33px; }
  .page-content .info {
    margin-bottom: 43px;
    display: flex; }
    .page-content .info-item {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      color: #BABBC3;
      line-height: 1.1;
      margin-right: 36px; }
    .page-content .info .circle {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background-color: #FFFFFF;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
      margin-right: 20px; }
  .page-content .content h3 {
    font-size: 21px;
    color: #606060;
    line-height: 1.2380952381;
    margin-bottom: 23px; }
  .page-content .content div, .page-content .content span, .page-content .content p, .page-content .content a, .page-content .content li {
    font-size: 18px;
    color: #606060;
    line-height: 1.2777777778;
    margin-bottom: 23px; }
  .page-content .content img {
    margin-bottom: 23px; }
  .page-content .content ul {
    list-style-position: outside;
    list-style-type: none;
    margin-bottom: 23px; }
    .page-content .content ul li {
      position: relative;
      display: list-item;
      padding-left: 23px; }
      .page-content .content ul li span {
        display: block; }
      .page-content .content ul li:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 6px;
        left: 0;
        width: 12px;
        height: 12px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-clip: padding-box;
        background-color: #E83192; }
  .page-content .content a {
    color: #E83192;
    text-decoration: underline; }

@media screen and (max-width: 1024px) {
  .knowledge-sub {
    padding-bottom: 19px; }
    .knowledge-sub .form-search {
      margin-bottom: 0; }
    .knowledge-sub:before {
      max-width: 991px;
      width: 100%;
      height: 871px;
      background-image: url("../img/tablet/page-knowledge-sub.png"); }
  .page-content .col-12:first-child h2 {
    margin-bottom: 34px; }
  .page-content .info .circle {
    width: 49px;
    height: 49px;
    margin-right: 20px; }
    .page-content .info .circle img {
      width: auto;
      height: 25px; } }

@media screen and (max-width: 575px) {
  .knowledge-sub {
    padding-bottom: 1px; }
    .knowledge-sub h1 {
      padding-top: 56px;
      margin-top: 0; }
  .page-content .info .circle {
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin-right: 9px; }
    .page-content .info .circle img {
      height: 16px; }
  .page-content .info-item {
    font-size: 15px;
    line-height: 1.4666666667; }
  .page-content .content h3 {
    font-size: 18px;
    margin-bottom: 15px; }
  .page-content .content div, .page-content .content span, .page-content .content p, .page-content .content a, .page-content .content li {
    font-size: 15px;
    color: #606060;
    line-height: 1.2666666667;
    margin-bottom: 15px; }
  .page-content .content img {
    margin-bottom: 15px; }
  .page-content .content ul {
    margin-bottom: 15px; } }
