.page-basket-payment {
  padding-top: 68px;
  padding-bottom: 70px; }
  .page-basket-payment h1 {
    display: flex;
    align-items: center;
    margin-bottom: 44px; }
    .page-basket-payment h1 img {
      margin-right: 15px; }
  .page-basket-payment p {
    font-size: 17px;
    font-weight: 500;
    color: #10162B;
    line-height: 1.2941176471;
    margin-bottom: 70px; }
  .page-basket-payment .box-inner {
    height: 100%;
    background-color: #FFFFFF;
    background-image: url("../img/bg/page-basket-payment.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background-clip: padding-box;
    box-shadow: 0 0 25px rgba(152, 38, 137, 0.16);
    padding: 39px 27px; }
    .page-basket-payment .box-inner .btn {
      font-size: 16px;
      justify-content: center;
      max-width: 220px;
      height: 69px;
      margin-left: 0;
      margin-right: auto; }
  .page-basket-payment .box-title {
    line-height: 1.24;
    text-transform: uppercase;
    margin-bottom: 54px; }
    .page-basket-payment .box-title:after {
      content: '';
      display: block;
      width: 74px;
      height: 3px;
      background-color: #19D08C;
      margin-top: 15px; }
  .page-basket-payment .box-desc {
    font-size: 20px;
    line-height: 1.45;
    margin-bottom: 42px; }
    .page-basket-payment .box-desc strong {
      font-weight: 500; }
    .page-basket-payment .box-desc-item {
      display: flex;
      align-self: center;
      border-bottom: 1px solid #E2E1E1; }
      .page-basket-payment .box-desc-item-name {
        font-size: 17px;
        font-weight: 600;
        color: #BABBC3;
        line-height: 2.5882352941; }
      .page-basket-payment .box-desc-item-text {
        font-size: 17px;
        line-height: 2.5882352941;
        margin-left: auto; }
      .page-basket-payment .box-desc-item-last {
        border-bottom: none; }

@media screen and (max-width: 1199px) {
  .page-basket-payment {
    padding-top: 40px;
    padding-bottom: 0; }
    .page-basket-payment .col-lg-12 {
      margin-bottom: 40px; }
    .page-basket-payment .box-inner .btn {
      height: 50px; } }

@media screen and (max-width: 660px) {
  .page-basket-payment h1 {
    margin-bottom: 30px; }
    .page-basket-payment h1 img {
      width: 25px;
      height: auto;
      margin-right: 10px; }
  .page-basket-payment p {
    margin-bottom: 30px; }
  .page-basket-payment .box-inner {
    padding: 25px 20px; }
  .page-basket-payment .box-title {
    font-size: 20px;
    line-height: 1.24;
    margin-bottom: 30px; }
  .page-basket-payment .box-desc {
    font-size: 16px;
    line-height: 1.24;
    margin-bottom: 30px; }
    .page-basket-payment .box-desc-item-name {
      font-size: 15px;
      line-height: 3.0666666667; }
    .page-basket-payment .box-desc-item-text {
      font-size: 15px;
      line-height: 3.0666666667; } }
