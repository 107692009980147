.navbar {
  display: flex;
  align-items: center; }
  .navbar-left, .navbar-right {
    display: flex;
    align-items: center;
    height: 98px; }
    .navbar-left a, .navbar-right a {
      display: block; }
  .navbar-left {
    margin-left: -15px; }
  .navbar-right {
    margin-left: auto;
    margin-right: -15px; }
    .navbar-right li {
      position: relative;
      height: 100%;
      margin: 0 20px; }
      .navbar-right li a {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 16px;
        font-weight: 700;
        color: rgba(26, 29, 57, 0.3);
        text-transform: uppercase; }
      .navbar-right li:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: -3px;
        width: 80px;
        height: 6px;
        background-color: transparent; }
      .navbar-right li:hover:after, .navbar-right li.active:after {
        background-color: #DC2BC4; }
      .navbar-right li:hover a, .navbar-right li.active a {
        color: #000000; }
      .navbar-right li.navbar-cart {
        display: flex;
        align-items: center;
        margin-right: 0;
        padding-left: 41px; }
        .navbar-right li.navbar-cart a {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          width: 50px;
          height: 50px;
          color: transparent;
          text-indent: -9999px;
          background-image: url("../img/icons/cart.svg"), linear-gradient(135deg, #ef3295 0%, #c624ff 200%);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: auto;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          border-radius: 10px;
          background-clip: padding-box;
          box-shadow: 0 6px 30px rgba(26, 29, 57, 0.53); }
        .navbar-right li.navbar-cart:hover a {
          background: url("../img/icons/cart-magenta.svg"), #FFF;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: auto; }
        .navbar-right li.navbar-cart:before {
          content: '';
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
          left: 0;
          width: 1px;
          height: 50px;
          background-color: #EAEAEA; }
        .navbar-right li.navbar-cart:after {
          display: none; }
  .navbar-menu {
    cursor: pointer;
    display: none;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: linear-gradient(135deg, #ef3295 0%, #c624ff 200%);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box;
    box-shadow: 0 6px 30px rgba(26, 29, 57, 0.53); }
    .navbar-menu .bar {
      position: relative;
      display: block;
      width: 20px;
      height: 2px;
      background-color: #FFFFFF;
      -moz-transition: background 0.3s ease-in-out;
      -o-transition: background 0.3s ease-in-out;
      -webkit-transition: background 0.3s ease-in-out;
      transition: background 0.3s ease-in-out; }
      .navbar-menu .bar:before, .navbar-menu .bar:after {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: #FFFFFF;
        -ms-transform-origin: 50% 50%;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        transition: top 0.3s 0.6s ease-in-out, transform 0.3s ease-in-out;
        transition-delay: 0.6s, 0s; }
      .navbar-menu .bar:before {
        top: -6px; }
      .navbar-menu .bar:after {
        top: 6px; }
  .navbar.menu-open .navbar-menu .bar {
    background-color: transparent; }
    .navbar.menu-open .navbar-menu .bar:before, .navbar.menu-open .navbar-menu .bar:after {
      top: 0;
      transition-delay: 0s, 0.5s; }
    .navbar.menu-open .navbar-menu .bar:before {
      transform: rotate3d(0, 0, 1, 45deg); }
    .navbar.menu-open .navbar-menu .bar:after {
      transform: rotate3d(0, 0, 1, -45deg); }
  .navbar.menu-open .navbar-right {
    max-height: calc(100vh - 96px); }

@media screen and (max-width: 1024px) {
  header .container {
    position: relative;
    max-width: unset; }
  .navbar-left {
    margin-left: 0; }
  .navbar-right {
    position: absolute;
    display: block;
    flex-wrap: wrap;
    top: 96px;
    left: 0;
    width: 100%;
    height: calc(100vh - 96px);
    max-height: 0;
    background-color: #FFFFFF;
    -moz-transition: max-height 0.3s ease-in-out;
    -o-transition: max-height 0.3s ease-in-out;
    -webkit-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    margin-right: 0; }
    .navbar-right li {
      max-width: 100%;
      flex-basis: 100%;
      height: auto; }
      .navbar-right li a {
        padding: 20px 0; }
      .navbar-right li:after {
        left: 0;
        -ms-transform: translate(0);
        -webkit-transform: translate(0);
        -moz-transform: translate(0);
        -o-transform: translate(0);
        transform: translate(0); }
      .navbar-right li.navbar-cart {
        padding-left: 0;
        margin-top: 15px; }
        .navbar-right li.navbar-cart a {
          justify-content: center;
          width: 270px;
          color: #FFFFFF;
          text-indent: 0;
          background-position: left center; }
          .navbar-right li.navbar-cart a:hover {
            color: #000000;
            background-position: left center; }
        .navbar-right li.navbar-cart:before {
          display: none; }
  .navbar-menu {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center; } }

@media screen and (max-width: 660px) {
  .navbar-left {
    height: 69px; }
    .navbar-left li a img {
      width: 141px;
      height: auto; }
  .navbar-right {
    top: 69px;
    height: calc(100vh - 69px); }
  .navbar.menu-open .navbar-right {
    max-height: calc(100vh - 69px); } }
