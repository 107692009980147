input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-style: normal;
  color: rgba(26, 29, 57, 0.3);
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  font-style: normal;
  color: rgba(26, 29, 57, 0.3);
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-style: normal;
  color: rgba(26, 29, 57, 0.3);
  opacity: 1;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out; }

input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield; }
  input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; }

.form-search {
  display: flex;
  align-items: center;
  max-width: 570px;
  height: 80px;
  background-color: #FFFFFF;
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
  background-clip: padding-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px 10px 10px 31px;
  margin-bottom: 60px; }

.form-input {
  font-size: 15px;
  font-weight: 600;
  color: #495057;
  text-transform: uppercase;
  height: 20px;
  border-left: 1px solid rgba(26, 29, 57, 0.13);
  padding: 5px 20px;
  margin-left: 20px; }

.form-submit {
  margin-left: auto;
  margin-right: 0; }

.form-group {
  position: relative;
  border-bottom: 1px solid #CBCBCB;
  margin-bottom: 22px; }
  .form-group .line {
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 1px;
    background-color: #EF3295;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }

.form-control {
  width: 100%;
  font-size: 18px;
  color: #606060;
  line-height: 1.2777777778;
  height: 59px;
  padding: 18px 27px; }
  .form-control:focus ~ .line {
    width: 100%; }

.form-select {
  font-family: "Mark Pro", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #606060;
  line-height: 1.2777777778;
  background-image: url("../img/icons/arrow-down-gray.svg");
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: transparent;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.form-textarea {
  resize: none; }
  .form-textarea-200 {
    height: 200px; }

.form-checkbox {
  border-bottom: none; }

.chk-radio {
  cursor: pointer;
  display: flex;
  position: relative;
  min-height: 32px;
  padding-left: 53px;
  margin-bottom: 12px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .chk-radio:hover .checkmark {
    box-shadow: 0 0 15px rgba(235, 25, 215, 0.58); }
  .chk-radio, .chk-radio a {
    font-size: 20px;
    color: #BABBC3;
    line-height: 1.1; }
  .chk-radio a {
    display: contents;
    text-decoration: underline;
    margin: 0 3px; }
    .chk-radio a:hover {
      text-decoration: underline; }
  .chk-radio label {
    margin-bottom: 0; }
  .chk-radio input {
    cursor: pointer;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0; }
    .chk-radio input:checked ~ .checkmark {
      border-color: #EF3295;
      background-color: #EF3295; }
    .chk-radio input:checked ~ .checkmark:after {
      display: block; }
  .chk-radio .checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    width: 32px;
    height: 32px;
    border: 1px solid #EF3295;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box;
    box-shadow: 0 0 20px rgba(235, 25, 215, 0.58);
    -moz-transition: box-shadow 300ms ease;
    -o-transition: box-shadow 300ms ease;
    -webkit-transition: box-shadow 300ms ease;
    transition: box-shadow 300ms ease; }
    .chk-radio .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: -2px;
      left: -2px;
      width: 33px;
      height: 33px;
      background-image: url("../img/icons/check-white.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto; }
  .chk-radio.reverse {
    line-height: 30px;
    padding-left: 0;
    padding-right: 40px; }
    .chk-radio.reverse .checkmark {
      top: -3px;
      right: -3px;
      left: auto; }

.qty {
  display: flex;
  justify-content: center;
  max-width: 177px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
  background-clip: padding-box;
  margin: 0 auto; }
  .qty input[type=number] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield; }
    .qty input[type=number]::-webkit-outer-spin-button, .qty input[type=number]::-webkit-inner-spin-button {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none; }
  .qty-minus, .qty-plus {
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    max-width: 55px;
    flex-basis: 55px;
    width: 100%;
    height: 55px;
    background-color: #FFFFFF;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background-clip: padding-box;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .qty-input {
    max-width: calc(100% - 110px);
    flex-basis: calc(100% - 110px);
    font-size: 18px;
    font-weight: 600;
    color: rgba(96, 96, 96, 0.3);
    text-align: center;
    background-color: transparent;
    padding: 0 5px; }
  .qty.sys {
    display: flex; }

@media screen and (max-width: 1024px) {
  .form-search {
    height: 74px; }
  .form-input {
    max-width: 165px;
    flex-basis: 165px;
    padding: 5px 10px;
    margin-left: 10px; } }

@media screen and (max-width: 660px) {
  .form-control {
    font-size: 15px;
    line-height: 1.2666666667;
    height: 47px;
    padding: 10px 27px; }
  .form-search {
    height: 58px;
    padding: 10px 10px 10px 16px;
    margin-bottom: 0; }
  .form-icon {
    width: 14px;
    height: auto; }
  .form-input {
    max-width: calc(100% - 168px);
    flex-basis: calc(100% - 168px);
    font-size: 12px; }
  .form-submit {
    max-width: 144px;
    flex-basis: 144px;
    font-size: 12px;
    height: 40px; }
  .chk-radio, .chk-radio a {
    font-size: 15px; } }
