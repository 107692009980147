.hosting-header {
  padding-bottom: 16.51vw; }
  .hosting-header h1 {
    padding-top: 12.74vw;
    margin-bottom: 25px; }
  .hosting-header h2 {
    margin-bottom: 15px; }
  .hosting-header h3 {
    margin-bottom: 30px; }
  .hosting-header-box {
    max-width: 565px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box;
    background-image: url("../img/bg/hosting-box.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    padding: 33px 27px 47px; }
    .hosting-header-box h4 {
      font-size: 20px;
      color: #FF2C9C;
      line-height: 1.6;
      text-transform: uppercase;
      margin-bottom: 20px; }
    .hosting-header-box p {
      font-size: 21px;
      color: #707070;
      line-height: 1.2380952381; }
  .hosting-header:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 886px;
    width: 50vw;
    height: 1234px;
    background-image: url("../img/desktop/hosting.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.hosting-why {
  padding-top: 138px; }
  .hosting-why:before {
    top: 0;
    left: 0;
    max-width: 886px;
    height: 1094px;
    background-image: url("../img/desktop/hosting-why.png"); }

.hosting-details {
  padding-top: 141px; }
  .hosting-details .hosting-details-slider {
    display: none; }
  .hosting-details h2 {
    margin-bottom: 49px; }
  .hosting-details .table-header {
    position: sticky;
    top: 98px; }
  .hosting-details .table-item {
    min-height: 110px;
    height: unset; }
    .hosting-details .table-item-last {
      border-bottom: none;
      padding-top: 32px; }
  .hosting-details .table-col {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
    .hosting-details .table-col div {
      font-size: 30px;
      font-weight: 700;
      color: #10162B;
      line-height: 1.1; }
    .hosting-details .table-col span {
      display: block;
      font-size: 17px;
      font-weight: 500;
      color: #BABBC3;
      line-height: 1.2352941176;
      margin-bottom: 7px; }
    .hosting-details .table-col p {
      font-size: 23px;
      font-weight: 600;
      color: #10162B;
      line-height: 1.3043478261; }
      .hosting-details .table-col p.table-vat {
        font-size: 17px;
        font-weight: 400;
        color: #10162B;
        line-height: 1.3529411765;
        margin-bottom: 39px; }
    .hosting-details .table-col a.btn {
      max-width: 167px;
      height: 49px;
      margin: 0 auto; }
  .hosting-details .table-icon {
    display: table;
    margin: 0 auto; }

.hosting-details-slider .swiper-wrapper {
  padding-top: 30px; }

.hosting-details-slider .swiper-slide {
  max-width: 305px; }

.hosting-details-slider .table-item {
  background-color: #FFFFFF;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  background-clip: padding-box;
  box-shadow: 0 0 15px rgba(220, 30, 170, 0.31); }
  .hosting-details-slider .table-item .hd-title {
    max-width: 100%;
    flex-basis: 100%;
    font-size: 25px;
    font-weight: 700;
    color: #10162B;
    line-height: 1.24;
    text-transform: uppercase;
    padding-top: 20px;
    margin-bottom: 1px; }
    .hosting-details-slider .table-item .hd-title:after {
      content: '';
      display: block;
      width: 104px;
      height: 3px;
      background-color: #FF2C9C;
      margin: 11px auto 0; }
  .hosting-details-slider .table-item .table-col {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 88px;
    border-bottom: 1px solid #D3D3D3; }
    .hosting-details-slider .table-item .table-col-last {
      border-bottom: none;
      margin-bottom: 19px; }
    .hosting-details-slider .table-item .table-col-price {
      border-bottom: none;
      padding-bottom: 33px; }
    .hosting-details-slider .table-item .table-col span {
      font-size: 22px;
      font-weight: 400;
      line-height: 1.2727272727;
      margin-bottom: -1px; }
    .hosting-details-slider .table-item .table-col p {
      font-size: 28px;
      line-height: 1.2857142857; }
      .hosting-details-slider .table-item .table-col p.table-vat {
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 23px; }
    .hosting-details-slider .table-item .table-col div {
      font-size: 36px; }
    .hosting-details-slider .table-item .table-col img {
      margin: 4px auto 0; }
    .hosting-details-slider .table-item .table-col .btn-gradient {
      min-width: 225px;
      height: 60px; }

.hosting-accessories {
  padding-top: 128px;
  padding-bottom: 70px; }
  .hosting-accessories h2 {
    margin-bottom: 82px; }
  .hosting-accessories .card-inner {
    background-image: url("../img/bg/hosting-accessories.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-border-radius: 44px;
    -moz-border-radius: 44px;
    border-radius: 44px;
    background-clip: padding-box;
    box-shadow: 0 0 36px rgba(152, 38, 137, 0.31);
    padding: 64px 15px 55px; }
  .hosting-accessories .card-title {
    font-size: 32px;
    font-weight: 700;
    color: #10162B;
    line-height: 1.4375;
    text-transform: uppercase;
    margin-bottom: 20px; }
    .hosting-accessories .card-title span:not(.card-text) {
      font-weight: 700;
      color: #EE3194; }
    .hosting-accessories .card-title:after {
      content: '';
      display: block;
      width: 80px;
      height: 3px;
      background-color: #EE3194;
      margin: 0 auto; }
  .hosting-accessories .card-text {
    display: block;
    font-size: 22px;
    color: #707070;
    line-height: 1.1;
    text-transform: none;
    margin-top: 3px;
    margin-bottom: 32px; }
  .hosting-accessories .card-price {
    font-size: 51px;
    font-weight: 700;
    color: #10162B;
    line-height: 1.1;
    margin-bottom: 4px; }
  .hosting-accessories .card-vat {
    font-size: 29px;
    color: #10162B;
    line-height: 1.1; }

@media screen and (max-width: 1024px) {
  .hosting-header {
    padding-bottom: 90px; }
    .hosting-header h1 {
      padding-top: 33.2vw;
      margin-bottom: 8px; }
    .hosting-header h2 {
      margin-bottom: -3px; }
    .hosting-header h3 {
      margin-bottom: 16px; }
    .hosting-header .hosting-header-box {
      padding: 13px 27px 30px; }
      .hosting-header .hosting-header-box h4 {
        margin-bottom: 10px; }
    .hosting-header:before {
      max-width: 991px;
      width: 100vw;
      height: 906px;
      background-image: url("../img/tablet/page-hosting.png"); }
  .hosting-packages {
    padding-bottom: 45px; }
    .hosting-packages .sl-package-vattext {
      max-width: 80%;
      margin: 0 auto; }
  .hosting-why {
    padding-top: 0; }
    .hosting-why:before {
      top: 54px;
      max-width: 422px;
      width: 100vw;
      height: 785px;
      background-image: url("../img/tablet/page-hosting2.png"); }
  .hosting-details {
    padding-top: 50px; }
    .hosting-details .hosting-details-slider {
      display: block; }
    .hosting-details .hosting-details-table {
      display: none; }
  .hosting-accessories {
    padding-top: 31px;
    padding-bottom: 0; }
    .hosting-accessories h2 {
      margin-bottom: 58px; }
    .hosting-accessories .card {
      margin-bottom: 48px; }
      .hosting-accessories .card-inner {
        padding: 59px 15px 48px; }
      .hosting-accessories .card-title {
        font-size: 29px;
        line-height: 1.4482758621;
        margin-bottom: 19px; }
      .hosting-accessories .card-text {
        font-size: 19px;
        line-height: 2.3157894737;
        margin-top: -10px;
        margin-bottom: 18px; }
      .hosting-accessories .card-price {
        font-size: 45px;
        margin-bottom: 0; }
      .hosting-accessories .card-vat {
        font-size: 25px;
        line-height: 1.4; } }

@media screen and (max-width: 660px) {
  .hosting-header {
    padding-bottom: 40px; }
    .hosting-header .col-md-7 {
      max-width: 100%;
      flex-basis: 100%; }
    .hosting-header h1, .hosting-header h2, .hosting-header h3 {
      text-align: center; }
    .hosting-header h2, .hosting-header h3 {
      font-size: 14px;
      line-height: 1.3571428571; }
    .hosting-header h1 {
      padding-top: 0;
      margin-bottom: 16px; }
    .hosting-header h2 {
      margin-bottom: 2px; }
    .hosting-header h3 {
      margin-bottom: 25px; }
    .hosting-header .hosting-header-box {
      padding: 14px 27px 19px; }
      .hosting-header .hosting-header-box h4, .hosting-header .hosting-header-box p {
        text-align: center; }
      .hosting-header .hosting-header-box h4 {
        font-size: 12px;
        line-height: 1.5833333333; }
      .hosting-header .hosting-header-box p {
        font-size: 12px;
        line-height: 1.25; }
    .hosting-header:before {
      display: none; }
  .hosting-why h2:after {
    margin-left: auto;
    margin-right: auto; }
  .hosting-details {
    padding-top: 30px; }
    .hosting-details h2 {
      margin-bottom: 4px; }
  .hosting-details-slider .swiper-wrapper {
    padding-top: 30px; }
  .hosting-details-slider .swiper-slide {
    max-width: 249px; }
  .hosting-details-slider .table-item {
    background-image: url("../img/bg/page-hosting.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto; }
    .hosting-details-slider .table-item .hd-title {
      font-size: 20px;
      line-height: 1.25;
      padding-top: 15px;
      margin-bottom: 2px; }
      .hosting-details-slider .table-item .hd-title:after {
        width: 77px;
        height: 2px;
        margin: 9px auto 0; }
    .hosting-details-slider .table-item .table-col {
      min-height: 71px; }
      .hosting-details-slider .table-item .table-col-last {
        margin-bottom: 15px; }
      .hosting-details-slider .table-item .table-col-price {
        padding-bottom: 25px; }
      .hosting-details-slider .table-item .table-col span {
        font-size: 17px;
        line-height: 1.2352941176;
        margin-bottom: -1px; }
      .hosting-details-slider .table-item .table-col p {
        font-size: 23px;
        line-height: 1.3043478261; }
        .hosting-details-slider .table-item .table-col p.table-vat {
          font-size: 17px;
          line-height: 1.3529411765;
          margin-bottom: 17px; }
      .hosting-details-slider .table-item .table-col div {
        font-size: 30px; }
      .hosting-details-slider .table-item .table-col img {
        margin: 4px auto 0; }
      .hosting-details-slider .table-item .table-col .btn-gradient {
        min-width: 167px;
        height: 49px;
        font-size: 15px; }
  .hosting-accessories .card {
    margin-bottom: 18px; }
    .hosting-accessories .card-inner {
      -webkit-border-radius: 27px;
      -moz-border-radius: 27px;
      border-radius: 27px;
      background-clip: padding-box;
      padding: 32px 15px 30px; }
    .hosting-accessories .card-title {
      font-size: 20px;
      line-height: 1.25;
      margin-bottom: 13px; }
      .hosting-accessories .card-title:after {
        width: 43px;
        height: 1px;
        margin-top: 1px; }
    .hosting-accessories .card-text {
      font-size: 15px;
      line-height: 1.8;
      margin-top: -8px;
      margin-bottom: 12px; }
    .hosting-accessories .card-price {
      font-size: 27px;
      margin-bottom: 0; }
    .hosting-accessories .card-vat {
      font-size: 15px;
      line-height: 1.3333333333; } }
