.swiper-button-prev, .swiper-button-next {
  width: 87px;
  height: 87px;
  background-color: #FFFFFF;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(136, 6, 115, 0.41); }
  .swiper-button-prev:after, .swiper-button-next:after {
    display: none; }

.swiper-button-prev {
  left: 30px;
  background-image: url("../img/icons/arrow-left-blue-big.svg"); }

.swiper-button-next {
  right: 30px;
  background-image: url("../img/icons/arrow-right-blue-big.svg"); }

@media screen and (max-width: 767px) {
  .swiper-button-prev {
    left: -47px;
    background-position: calc(100% - 17px) center; }
  .swiper-button-next {
    right: -47px;
    background-position: 17px center; } }
