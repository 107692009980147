@charset "UTF-8";
.sl-package-slider .swiper-slide {
  max-width: 304px;
  opacity: 0.7; }
  .sl-package-slider .swiper-slide-prev, .sl-package-slider .swiper-slide-next, .sl-package-slider .swiper-slide-active {
    opacity: 1; }

.sl-package-slider .swiper-wrapper {
  padding-top: 30px;
  padding-bottom: 30px; }

.sl-package-item {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  background-clip: padding-box;
  box-shadow: 0 0 36px rgba(152, 38, 137, 0.31); }

.sl-package-header {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  height: 132px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #1A1D39;
  -webkit-border-radius: 24px 24px 0 0;
  -moz-border-radius: 24px 24px 0 0;
  border-radius: 24px 24px 0 0;
  background-clip: padding-box; }
  .sl-package-header h3 {
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: uppercase; }

.sl-package-gift {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  top: -23px;
  right: -17px;
  min-width: 87px;
  min-height: 87px;
  max-height: 87px;
  height: 100%;
  background-color: #FFFFFF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(243, 36, 209, 0.16); }
  .sl-package-gift p {
    font-size: 20px;
    font-weight: 700;
    color: #AF2B78;
    line-height: 0.7;
    text-transform: uppercase; }
  .sl-package-gift span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #10162B;
    text-transform: uppercase; }

.sl-package-content {
  background-color: #FFFFFF;
  background-image: url("../img/bg/slider-packages.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 0 0 24px 24px;
  -moz-border-radius: 0 0 24px 24px;
  border-radius: 0 0 24px 24px;
  background-clip: padding-box;
  padding: 20px 15px; }
  .sl-package-content ul {
    display: table;
    padding-left: 23px;
    margin: 0 auto 32px; }
    .sl-package-content ul li {
      position: relative;
      font-size: 15px;
      color: #707070;
      line-height: 2.3333333333; }
      .sl-package-content ul li:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 11px;
        left: -23px;
        width: 17px;
        height: 13px;
        background-image: url("../img/icons/check-magenta.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto; }

.sl-package-title {
  font-size: 18px;
  font-weight: 600;
  color: #707070;
  line-height: 1.3888888889;
  text-align: center;
  margin-bottom: 17px; }
  .sl-package-title.after-line:after {
    margin: 11px auto 0; }

.sl-package-desc {
  font-size: 15px;
  font-weight: 600;
  color: #EE3194;
  line-height: 1.3333333333;
  text-align: center;
  margin-bottom: 32px; }

.sl-package-price {
  font-size: 30px;
  font-weight: 700;
  color: #10162B;
  line-height: 1;
  text-align: center;
  margin-bottom: 18px; }
  .sl-package-price span {
    display: block;
    font-size: 17px;
    font-weight: 400; }

.sl-package-discount {
  font-size: 13px;
  color: rgba(25, 29, 57, 0.3);
  line-height: 1.2307692308;
  margin-bottom: 30px; }

.sl-package-tocart {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  border-radius: 36px;
  background-clip: padding-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  padding: 10px 10px 10px 31px; }
  .sl-package-tocart .btn {
    width: 122px;
    height: 49px;
    margin-right: 0;
    margin-left: auto; }

.sl-package-more {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: rgba(26, 29, 57, 0.3);
  line-height: 1.2142857143; }
  .sl-package-more img {
    margin-right: 10px; }

.sl-package-vattext {
  font-size: 16px;
  color: #AFAFAF;
  line-height: 1.375; }

@media screen and (max-width: 1199px) {
  .sl-package-vat.col-6-offset {
    margin-left: 0; }
  .sl-package-vattext {
    text-align: center; } }

@media screen and (max-width: 768px) {
  .sl-package-slider .swiper-slide {
    max-width: 252px;
    flex-basis: 252px; }
  .sl-package-header {
    height: 97px; }
  .sl-package-content {
    padding: 12px 15px; }
  .sl-package-title {
    font-size: 16px;
    line-height: 1.375; }
  .sl-package-desc {
    font-size: 14px;
    line-height: 1.4285714286; }
  .sl-package–price {
    font-size: 33px; }
    .sl-package–price span {
      font-size: 19px;
      line-height: 1.2631578947; }
  .sl-package-tocart {
    padding: 10px; }
    .sl-package-tocart .btn {
      width: 102px;
      font-size: 14px; } }

@media screen and (max-width: 575px) {
  .sl-package-header {
    height: 80px; }
    .sl-package-header h3 {
      font-size: 21px; }
  .sl-package-title.after-line:after {
    margin: 5px auto 0; }
  .sl-package-desc {
    margin-bottom: 15px; }
  .sl-package-content ul {
    margin: 0 auto 15px; }
  .sl-package–price {
    font-size: 28px; }
  .sl-package-tocart {
    padding: 6px; }
    .sl-package-tocart .btn {
      height: 43px; }
  .sl-package-gift {
    min-width: 70px;
    min-height: 70px;
    max-height: 70px; }
    .sl-package-gift p {
      font-size: 16px; }
    .sl-package-gift span {
      font-size: 13px; } }
