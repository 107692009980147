body h1, body .h1, body h2, body .h2, body h3, body .h3, body h4, body .h4, body h5, body .h5, body h6, body .h6 {
  font-weight: 700;
  line-height: 1.1; }

.hero {
  font-size: 45px; }

body h1, body .h1 {
  font-size: 65px;
  text-transform: uppercase;
  line-height: 1.2285714286; }

body h2, body .h2 {
  font-size: 40px; }

body h3, body .h3 {
  font-size: 29px; }

body h4, body .h4 {
  font-size: 28px; }

body h5, body .h5 {
  font-size: 25px; }

body h6, body .h6 {
  font-size: 24px; }

body a, body .a, body p, body .p, body span, body ul, body ol {
  font-weight: 400;
  line-height: 1.5; }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MarkPro-Light.eot?#prefix");
  src: url("fonts/MarkPro-Light.eot?#iefix") format("eot"), url("fonts/MarkPro-Light.otf") format("otf"), url("fonts/MarkPro-Light.woff") format("woff"), url("fonts/MarkPro-Light.woff2") format("woff2"), url("fonts/MarkPro-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/MarkPro-Medium.eot?#prefix");
  src: url("fonts/MarkPro-Medium.eot?#iefix") format("eot"), url("fonts/MarkPro-Medium.otf") format("otf"), url("fonts/MarkPro-Medium.woff") format("woff"), url("fonts/MarkPro-Medium.woff2") format("woff2"), url("fonts/MarkPro-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/MarkPro-Bold.eot?#prefix");
  src: url("fonts/MarkPro-Bold.eot?#iefix") format("eot"), url("fonts/MarkPro-Bold.otf") format("otf"), url("fonts/MarkPro-Bold.woff") format("woff"), url("fonts/MarkPro-Bold.woff2") format("woff2"), url("fonts/MarkPro-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/MarkPro-Heavy.eot?#prefix");
  src: url("fonts/MarkPro-Heavy.eot?#iefix") format("eot"), url("fonts/MarkPro-Heavy.otf") format("otf"), url("fonts/MarkPro-Heavy.woff") format("woff"), url("fonts/MarkPro-Heavy.woff2") format("woff2"), url("fonts/MarkPro-Heavy.ttf") format("truetype"); }

@media screen and (max-width: 1366px) {
  body h1, body .h1 {
    font-size: 60px; } }

@media screen and (max-width: 1199px) {
  body h1, body .h1, .hero {
    font-size: 50px;
    line-height: 1.2; } }

@media screen and (max-width: 1024px) {
  body h2, body .h2 {
    font-size: 35px;
    line-height: 1.2571428571; } }

@media screen and (max-width: 767px) {
  body h1, body .h1, body h2, body .h2 {
    font-size: 21px;
    line-height: 1.2857142857; } }
