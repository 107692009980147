.wwh-cards, .wwh-cards-more {
  max-width: 747px;
  flex-basis: 747px;
  padding: 0 15px; }

.wwh-card {
  display: flex;
  align-items: center;
  height: 156px;
  background-color: #FFFFFF;
  background-image: url("../img/wave-03.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box;
  box-shadow: 0 0 20px rgba(64, 161, 96, 0.3);
  overflow: hidden;
  margin-bottom: 29px; }
  .wwh-card-title {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    max-width: 37px;
    flex-basis: 37px;
    min-width: 37px;
    height: 100%; }
    .wwh-card-title p {
      font-size: 13px;
      font-weight: 600;
      color: #FFFFFF;
      text-transform: uppercase;
      -ms-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg); }
  .wwh-card-content {
    max-width: 342px;
    flex-basis: 342px;
    position: relative;
    padding: 15px; }
    .wwh-card-content:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 0;
      width: 1px;
      height: 102px;
      background-color: #D3D3D3; }
  .wwh-card-name {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.45; }
  .wwh-card-gift {
    display: flex;
    align-items: center;
    margin-top: 19px; }
    .wwh-card-gift-circle {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      max-width: 47px;
      flex-basis: 47px;
      min-width: 47px;
      height: 47px;
      background-color: #1A1D39;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box;
      margin-right: 7px; }
    .wwh-card-gift-text {
      position: relative;
      max-width: 234px;
      flex-basis: 234px;
      font-size: 15px;
      color: #606060;
      padding-left: 24px; }
      .wwh-card-gift-text:before {
        content: '';
        display: none;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: -10px;
        width: 45px;
        height: 45px;
        background-image: url("../img/icons/gift-circle.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto; }
    .wwh-card-gift-mobil {
      display: none; }
  .wwh-card-price {
    position: relative;
    max-width: 160px;
    flex-basis: 160px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center; }
    .wwh-card-price:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 0;
      width: 1px;
      height: 102px;
      background-color: #D3D3D3; }
    .wwh-card-price-title {
      font-size: 13px;
      font-weight: 600;
      color: rgba(26, 29, 57, 0.3);
      line-height: 1.2307692308;
      margin-bottom: 6px; }
    .wwh-card-price-orig {
      font-size: 30px;
      font-weight: 700;
      line-height: 1;
      color: #10162B; }
    .wwh-card-price-discount {
      font-size: 30px;
      font-weight: 700;
      line-height: 1; }
    .wwh-card-price-vat {
      font-size: 17px;
      color: #10162B;
      line-height: 1.3529411765; }
    .wwh-card-price.wwh-card-price-hasdiscount .wwh-card-price-orig {
      font-size: 20px;
      font-weight: 500;
      color: #BABBC3;
      text-decoration: line-through; }
  .wwh-card-cart {
    max-width: calc(100% - 539px);
    flex-basis: calc(100% - 539px);
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px; }
    .wwh-card-cart a {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      width: 152px;
      height: 49px;
      font-size: 15px;
      font-weight: 600;
      text-align: center;
      -webkit-border-radius: 44px;
      -moz-border-radius: 44px;
      border-radius: 44px;
      background-clip: padding-box;
      box-shadow: 0 0 20px rgba(100, 172, 136, 0.53); }
    .wwh-card-cart-disabled {
      pointer-events: none;
      filter: grayscale(50%); }
  .wwh-card-free .wwh-card-title {
    background-color: #19D08C; }
  .wwh-card-free .wwh-card-name {
    color: #19D08C; }
  .wwh-card-free .wwh-card-price.wwh-card-price-hasdiscount .wwh-card-price-discount {
    color: #19D08C; }
  .wwh-card-free .wwh-card-cart a {
    color: #FFFFFF;
    background-color: #19D08C; }
  .wwh-card-reregister .wwh-card-title {
    background-color: #EF3295; }
  .wwh-card-reregister .wwh-card-name {
    color: #EF3295; }
  .wwh-card-reregister .wwh-card-price.wwh-card-price-hasdiscount .wwh-card-price-discount {
    color: #EF3295; }
  .wwh-card-reregister .wwh-card-cart a {
    color: #EF3295;
    background-color: #FFFFFF; }
  .wwh-card-skeleton {
    width: 0;
    animation: fullView 0.5s forwards linear; }
    .wwh-card-skeleton .wwh-card-title {
      background-color: #707070; }
      .wwh-card-skeleton .wwh-card-title span {
        display: block;
        width: 13px;
        height: 64px;
        background-color: #FFFFFF;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        background-clip: padding-box; }
    .wwh-card-skeleton .wwh-card-name .rect {
      width: 187px; }
    .wwh-card-skeleton .wwh-card-gift {
      flex-direction: column;
      align-items: flex-start; }
      .wwh-card-skeleton .wwh-card-gift .rect {
        width: 114px; }
      .wwh-card-skeleton .wwh-card-gift .rect2 {
        width: 142px;
        margin-top: 15px; }
    .wwh-card-skeleton .wwh-card-price-title .rect {
      width: 114px; }
    .wwh-card-skeleton .wwh-card-price-orig .rect, .wwh-card-skeleton .wwh-card-price-vat .rect {
      width: 128px; }
    .wwh-card-skeleton .wwh-card-price-orig {
      margin-top: 4px; }
    .wwh-card-skeleton .wwh-card-price-vat {
      margin-top: 10px; }
    .wwh-card-skeleton .wwh-card-cart .rect {
      width: 151px;
      height: 49px; }
    .wwh-card-skeleton .rect {
      display: block;
      height: 21px; }
    .wwh-card-skeleton .animate {
      animation: shimmer 2s infinite linear;
      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
      background-size: 200px 100%; }

@keyframes fullView {
  100% {
    width: 100%; } }

@keyframes shimmer {
  0% {
    background-position: -200px 0; }
  100% {
    background-position: 200px 0; } }

@media screen and (max-width: 768px) {
  .wwh-card-content {
    max-width: 327px;
    flex-basis: 327px; }
  .wwh-card-price {
    max-width: 154px;
    flex-basis: 154px; }
  .wwh-card-cart {
    max-width: calc(100% - 518px);
    flex-basis: calc(100% - 518px); } }

@media screen and (max-width: 767px) {
  .wwh-card {
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 31px; }
    .wwh-card-title {
      max-width: 20px;
      flex-basis: 20px;
      min-width: 20px;
      height: 75px;
      -webkit-border-radius: 10px 0 0 10px;
      -moz-border-radius: 10px 0 0 10px;
      border-radius: 10px 0 0 10px;
      background-clip: padding-box; }
      .wwh-card-title p {
        font-size: 11px; }
    .wwh-card-name {
      font-size: 24px; }
    .wwh-card-gift {
      display: none; }
      .wwh-card-gift-text {
        font-size: 13px;
        line-height: 1.2307692308;
        text-align: center;
        margin: 0 auto; }
        .wwh-card-gift-text strong {
          color: #000000; }
        .wwh-card-gift-text:before {
          display: inline-block; }
      .wwh-card-gift-mobil {
        max-width: 100%;
        flex-basis: 100%;
        display: block;
        padding-top: 7px; }
    .wwh-card-content {
      max-width: calc(100% - 20px);
      flex-basis: calc(100% - 20px);
      border-bottom: 1px solid rgba(211, 211, 211, 0.6);
      padding: 21px 15px; }
      .wwh-card-content:after {
        display: none; }
    .wwh-card-price {
      max-width: 100%;
      flex-basis: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(211, 211, 211, 0.6);
      padding-top: 9px;
      padding-bottom: 5px; }
      .wwh-card-price:after {
        display: none; }
      .wwh-card-price-title {
        max-width: 100%;
        flex-basis: 100%;
        font-size: 17px;
        line-height: 1.1764705882; }
      .wwh-card-price-orig {
        max-width: 100%;
        flex-basis: 100%;
        font-size: 25px; }
      .wwh-card-price-discount {
        font-size: 25px; }
      .wwh-card-price-vat {
        font-size: 16px; }
      .wwh-card-price.wwh-card-price-hasdiscount .wwh-card-price-orig, .wwh-card-price.wwh-card-price-hasdiscount .wwh-card-price-discount {
        max-width: 50%;
        flex-basis: 50%; }
      .wwh-card-price.wwh-card-price-hasdiscount .wwh-card-price-orig {
        font-size: 25px;
        text-align: right;
        padding-right: 2px; }
      .wwh-card-price.wwh-card-price-hasdiscount .wwh-card-price-discount {
        text-align: left;
        padding-left: 2px; }
    .wwh-card-cart {
      max-width: 100%;
      flex-basis: 100%;
      padding-bottom: 13px;
      margin-top: 14px; }
      .wwh-card-cart a {
        width: auto;
        min-width: 152px;
        height: 40px;
        font-size: 18px;
        text-transform: uppercase;
        padding: 5px 15px; } }

@media screen and (max-width: 660px) {
  .wwh-cards, .wwh-cards-more {
    padding: 0; } }
