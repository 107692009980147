.btn {
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  background-clip: padding-box;
  box-shadow: 0 6px 30px rgba(26, 29, 57, 0.53); }
  .btn-gradient {
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    text-transform: uppercase;
    background-image: linear-gradient(135deg, #EF3295 0%, #C624FF 200%); }
    .btn-gradient:hover, .btn-gradient:focus {
      color: #C626F9;
      background-image: none;
      background-color: #FFFFFF; }
  .btn-green {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    width: 230px;
    height: 49px;
    font-size: 22px;
    font-weight: 600;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: #19D08C;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    background-clip: padding-box;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.34);
    padding: 5px 28px;
    margin-left: auto;
    margin-right: 0; }
    .btn-green-alt {
      justify-content: center;
      font-size: 15px;
      width: auto; }
  .btn-white {
    font-size: 15px;
    font-weight: 600;
    color: #EF3295;
    text-transform: uppercase;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(100, 172, 136, 0.53);
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    background-clip: padding-box; }
    .btn-white:hover, .btn-white:focus {
      color: #C626F9; }
  .btn-h-56 {
    height: 56px; }
  .btn-h-68 {
    height: 68px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    border-radius: 34px;
    background-clip: padding-box; }
  .btn-h-75 {
    height: 75px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box; }
  .btn-w-200 {
    max-width: 200px;
    flex-basis: 200px; }
  .btn-center {
    margin: 0 auto; }
