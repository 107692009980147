.page-basket-hosting .wwh-cards {
  padding: 0; }

.page-basket-hosting .wwh-card {
  position: relative;
  height: auto;
  min-height: 156px;
  padding-left: 31px; }
  .page-basket-hosting .wwh-card:last-child {
    margin-bottom: 0; }
  .page-basket-hosting .wwh-card-content {
    max-width: 364px;
    flex-basis: 364px;
    align-self: flex-start;
    padding-top: 25px; }
  .page-basket-hosting .wwh-card-title {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #EF3295; }
    .page-basket-hosting .wwh-card-title p {
      font-size: 18px;
      line-height: 1.2222222222; }
  .page-basket-hosting .wwh-card-name {
    font-size: 20px;
    line-height: 1.25;
    text-transform: uppercase;
    margin-bottom: 11px; }
  .page-basket-hosting .wwh-card-txt {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 5px; }
  .page-basket-hosting .wwh-card-txt2 {
    font-size: 14px;
    font-weight: 600;
    color: #BABBC3;
    line-height: 1.2857142857; }
  .page-basket-hosting .wwh-card-gift {
    margin-top: 9px; }
    .page-basket-hosting .wwh-card-gift-circle {
      max-width: 27px;
      flex-basis: 27px;
      min-width: 27px;
      height: 27px; }
    .page-basket-hosting .wwh-card-gift-text {
      font-weight: 600;
      padding-left: 0; }
  .page-basket-hosting .wwh-card-price {
    max-width: 170px;
    flex-basis: 170px; }
  .page-basket-hosting .wwh-card-cart {
    max-width: calc(100% - 519px);
    flex-basis: calc(100% - 519px);
    margin: 0 auto; }

@media screen and (max-width: 767px) {
  .page-basket-hosting .wwh-card-content {
    padding: 21px 23px; }
  .page-basket-hosting .wwh-card-gift {
    display: flex; }
    .page-basket-hosting .wwh-card-gift-circle {
      display: none; }
    .page-basket-hosting .wwh-card-gift-text {
      margin-top: 10px; } }

@media screen and (max-width: 768px) {
  .page-basket-hosting .wwh-card {
    padding-left: 0; }
    .page-basket-hosting .wwh-card-title p {
      font-size: 11px; }
    .page-basket-hosting .wwh-card-content, .page-basket-hosting .wwh-card-price {
      max-width: 100%;
      flex-basis: 100%;
      justify-content: center;
      text-align: center; }
    .page-basket-hosting .wwh-card-cart {
      margin-top: 13px; }
  .page-basket-hosting .basket-right {
    margin-top: 40px; } }
