html.page-loaded .tr {
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  html.page-loaded .tr-600 {
    -moz-transition: all 600ms ease-in-out;
    -o-transition: all 600ms ease-in-out;
    -webkit-transition: all 600ms ease-in-out;
    transition: all 600ms ease-in-out; }

main {
  padding-top: 98px; }

@media screen and (max-width: 660px) {
  main {
    padding-top: 69px; } }
