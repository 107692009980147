.starts-with-domain {
  position: relative; }
  .starts-with-domain h1 {
    padding-top: 15.2vw;
    margin-bottom: 23px; }
  .starts-with-domain .section-subtitle {
    margin-bottom: 30px; }
  .starts-with-domain:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 991px;
    width: 50vw;
    height: 1069px;
    background-image: url("../img/desktop/starts-with-domain.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.popular-domains {
  padding-top: 10vw;
  padding-bottom: 96px; }
  .popular-domains h2 {
    margin-bottom: 52px; }
  .popular-domains .swiper-wrapper {
    max-width: 1310px;
    justify-content: space-between;
    margin: 0 auto; }
  .popular-domains .card-item {
    max-width: 382px;
    flex-basis: 382px;
    flex-grow: 1;
    flex-basis: 0;
    padding: 50px 30px 30px; }
  .popular-domains .card-inner {
    position: relative;
    text-align: center;
    background-color: #FFFFFF;
    background-image: url("../img/bg/main-card-01.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-border-radius: 44px;
    -moz-border-radius: 44px;
    border-radius: 44px;
    background-clip: padding-box;
    box-shadow: 0 0 36px rgba(152, 38, 137, 0.31);
    padding: 51px 20px 36px; }
  .popular-domains .card-title {
    margin-bottom: 31px; }
  .popular-domains .card-subtitle {
    font-size: 20px;
    font-weight: 600;
    color: #707070;
    line-height: 1.45;
    margin-bottom: 23px; }
  .popular-domains .card-desc {
    font-size: 20px;
    color: #10162B;
    line-height: 1.25;
    margin-bottom: 33px; }
    .popular-domains .card-desc:after {
      content: '';
      display: block;
      width: 80px;
      height: 3px;
      background-color: #EE3194;
      margin: 15px auto 0; }
  .popular-domains .card-price {
    font-size: 30px;
    font-weight: 700;
    color: #3C1E71;
    line-height: 1;
    margin-bottom: 37px; }
  .popular-domains .card-gift {
    position: absolute;
    top: -50px;
    right: -50px;
    display: flex;
    width: 145px;
    height: 145px;
    border: 1px solid #1A1D39;
    background-color: #1A1D39;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box;
    align-self: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 16px 5px; }
    .popular-domains .card-gift img {
      display: block; }
    .popular-domains .card-gift p {
      display: block;
      max-width: 100%;
      flex-basis: 100%;
      font-size: 14px;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 1.2857142857; }
  .popular-domains .text-help {
    font-size: 20px;
    color: #707070;
    line-height: 1.45; }
    .popular-domains .text-help:first-child {
      margin-bottom: 29px; }

.domain-endings {
  padding-bottom: 60px; }
  .domain-endings h2 {
    margin-bottom: 58px; }
  .domain-endings .text-help {
    display: none;
    font-size: 15px;
    color: #707070;
    line-height: 1.5333333333;
    margin-bottom: 20px; }
  .domain-endings .table-col {
    padding: 0 15px; }
    .domain-endings .table-col-1 {
      max-width: 240px;
      flex-basis: 240px; }
    .domain-endings .table-col-2 {
      max-width: 240px;
      flex-basis: 240px; }
    .domain-endings .table-col-3 {
      max-width: 240px;
      flex-basis: 240px; }
    .domain-endings .table-col-4 {
      max-width: 240px;
      flex-basis: 240px; }
    .domain-endings .table-col-5 {
      max-width: calc(100% - (4 * 240px));
      flex-basis: calc(100% - (4 * 240px)); }
  .domain-endings .table-item .table-col {
    color: #3C1E71; }
    .domain-endings .table-item .table-col-1 {
      font-size: 30px;
      font-weight: 700;
      color: #10162B;
      text-align: left; }

@media screen and (max-width: 1366px) {
  .domain-endings .table-item .table-col-1, .domain-endings .table-header .table-col-1 {
    max-width: 210px;
    flex-basis: 210px;
    font-size: 25px; }
  .domain-endings .table-item .table-col-2, .domain-endings .table-header .table-col-2 {
    max-width: 200px;
    flex-basis: 200px; }
  .domain-endings .table-item .table-col-3, .domain-endings .table-header .table-col-3 {
    max-width: 200px;
    flex-basis: 200px; }
  .domain-endings .table-item .table-col-4, .domain-endings .table-header .table-col-4 {
    max-width: 200px;
    flex-basis: 200px; }
  .domain-endings .table-item .table-col-5, .domain-endings .table-header .table-col-5 {
    max-width: calc(100% - ((3 * 200px) + 210px));
    flex-basis: calc(100% - ((3 * 200px) + 210px)); } }

@media screen and (max-width: 1199px) {
  .popular-domains .card-item {
    max-width: 333px;
    flex-basis: 333px;
    width: 100%; }
    .popular-domains .card-item .sl-package-tocart {
      padding: 10px; }
    .popular-domains .card-item .sl-package-more {
      font-size: 13px; }
    .popular-domains .card-item .sl-package .btn {
      max-width: 106px;
      font-size: 12px; }
  .popular-domains .card-price {
    font-size: 24px;
    line-height: 1; }
  .popular-domains .card-title {
    font-size: 35px; }
  .popular-domains .card-desc {
    padding: 0 5px; }
  .popular-domains .card-subtitle {
    font-size: 16px;
    line-height: 1.5; }
  .popular-domains .card-gift {
    right: -20px;
    width: 117px;
    height: 117px;
    font-size: 10px;
    line-height: 1.4; }
    .popular-domains .card-gift img {
      width: 37px;
      height: auto; }
  .domain-endings .table-header .table-col-1, .domain-endings .table-header .table-col-2, .domain-endings .table-item .table-col-1, .domain-endings .table-item .table-col-2 {
    max-width: 250px;
    flex-basis: 250px; }
  .domain-endings .table-header .table-col-3, .domain-endings .table-header .table-col-4, .domain-endings .table-item .table-col-3, .domain-endings .table-item .table-col-4 {
    display: none; }
  .domain-endings .table-header .table-col-5, .domain-endings .table-item .table-col-5 {
    max-width: calc(100% - 500px);
    flex-basis: calc(100% - 500px); } }

@media screen and (max-width: 1024px) {
  .starts-with-domain h1 {
    padding-top: 49.8vw;
    padding-right: 10px;
    margin-bottom: 16px; }
    .starts-with-domain h1 br {
      display: none; }
  .starts-with-domain .section-subtitle {
    padding-right: 25px; }
  .starts-with-domain .col-6 {
    max-width: 435px; }
  .starts-with-domain .form-search {
    max-width: 380px;
    margin: 0 auto 60px; }
  .starts-with-domain:before {
    max-width: 991px;
    width: 100vw;
    height: 921px;
    background-image: url("../img/tablet/page-domain.png"); }
  .domain-endings .text-help {
    display: block; }
  .domain-endings .table-header {
    height: 55px;
    -webkit-border-radius: 24px 24px 0 0;
    -moz-border-radius: 24px 24px 0 0;
    border-radius: 24px 24px 0 0;
    background-clip: padding-box; }
    .domain-endings .table-header .table-col {
      font-size: 16px; }
      .domain-endings .table-header .table-col-1 {
        font-size: 16px;
        text-align: left; }
        .domain-endings .table-header .table-col-1 strong {
          font-weight: 400; }
      .domain-endings .table-header .table-col-1, .domain-endings .table-header .table-col-2 {
        max-width: 50%;
        flex-basis: 50%; }
      .domain-endings .table-header .table-col-5 {
        display: none; }
  .domain-endings .table-inner {
    background-image: #FFFFFF;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    background-clip: padding-box;
    box-shadow: 0 0 15px rgba(220, 30, 170, 0.31); }
  .domain-endings .table-item {
    min-height: 125px;
    height: auto; }
    .domain-endings .table-item:last-child {
      border-bottom: none; }
    .domain-endings .table-item .table-col {
      padding: 10px 15px; }
      .domain-endings .table-item .table-col .old, .domain-endings .table-item .table-col .new {
        font-size: 16px; }
      .domain-endings .table-item .table-col-1 {
        max-width: 50%;
        flex-basis: 50%; }
      .domain-endings .table-item .table-col-2 {
        max-width: 50%;
        flex-basis: 50%;
        padding: 15px 0; }
      .domain-endings .table-item .table-col-5 {
        max-width: 100%;
        flex-basis: 100%;
        padding-top: 0; } }

@media screen and (max-width: 660px) {
  .starts-with-domain .col-6 {
    max-width: 100%;
    flex-basis: 100%; }
  .starts-with-domain h1 {
    text-align: center;
    padding-top: 0;
    margin-top: -25px; }
  .starts-with-domain .section-subtitle {
    font-size: 14px;
    line-height: 1.3571428571;
    text-align: center; }
  .starts-with-domain .form-search {
    margin: 0 auto 50px; }
  .popular-domains {
    padding-bottom: 54px; }
    .popular-domains h2 {
      margin-bottom: 0; }
    .popular-domains .card-desc {
      display: none; }
    .popular-domains .card-gift {
      top: -30px;
      right: 0;
      width: 87px;
      height: 87px; }
      .popular-domains .card-gift p {
        font-size: 10px;
        line-height: 1.2; }
      .popular-domains .card-gift img {
        width: 27px; }
    .popular-domains .text-help {
      font-size: 15px;
      line-height: 1.5333333333; }
      .popular-domains .text-help:first-child {
        margin-bottom: 0; }
      .popular-domains .text-help:last-child {
        font-weight: 600; }
  .domain-endings {
    padding-bottom: 20px; }
    .domain-endings h2 {
      margin-bottom: 24px; } }

@media screen and (max-width: 575px) {
  .domain-endings .table-header {
    display: none; }
  .domain-endings .table-item {
    height: auto;
    min-height: 125px; }
    .domain-endings .table-item .table-col-1, .domain-endings .table-item .table-col-2, .domain-endings .table-item .table-col-5 {
      max-width: 100%;
      flex-basis: 100%;
      text-align: center; }
    .domain-endings .table-item .table-col-2:before {
      content: attr(data-text);
      display: block;
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px; } }
