.page-basket-order.page-basket-payment {
  padding-top: 41px; }
  .page-basket-order.page-basket-payment h1 {
    margin-bottom: 85px; }
  .page-basket-order.page-basket-payment .box-inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }
  .page-basket-order.page-basket-payment .box-title {
    max-width: calc(100% - 150px);
    flex-basis: calc(100% - 150px); }
  .page-basket-order.page-basket-payment .box-desc {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 0; }
  .page-basket-order.page-basket-payment .box-edit {
    cursor: pointer;
    max-width: 140px;
    flex-basis: 140px;
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 19px;
    font-weight: 600;
    color: #CCCDD2;
    margin-left: auto; }
    .page-basket-order.page-basket-payment .box-edit img {
      margin-right: 5px; }
  .page-basket-order.page-basket-payment .box-orders {
    margin-top: 60px;
    margin-bottom: 45px; }
  .page-basket-order.page-basket-payment .box-order {
    width: 100%;
    font-size: 19px; }
    .page-basket-order.page-basket-payment .box-order-col-1 {
      max-width: 490px;
      flex-basis: 490px; }
    .page-basket-order.page-basket-payment .box-order-col-2 {
      max-width: calc((100% - 490px) / 4);
      flex-basis: calc((100% - 490px) / 4);
      text-align: center; }
    .page-basket-order.page-basket-payment .box-order-header, .page-basket-order.page-basket-payment .box-order-item, .page-basket-order.page-basket-payment .box-order-footer {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
    .page-basket-order.page-basket-payment .box-order-header {
      font-weight: 600;
      color: #BABBC3;
      margin-bottom: 11px; }
    .page-basket-order.page-basket-payment .box-order-footer {
      padding: 42px 0 17px; }
      .page-basket-order.page-basket-payment .box-order-footer .box-order-col-1 {
        font-weight: 600;
        color: #BABBC3; }
    .page-basket-order.page-basket-payment .box-order-item {
      align-items: center;
      border-bottom: 1px solid #E2E1E1;
      padding: 15px 0; }
      .page-basket-order.page-basket-payment .box-order-item .box-order-col-1 strong {
        display: block;
        font-weight: 500;
        margin-bottom: 11px; }
      .page-basket-order.page-basket-payment .box-order-item .box-order-col-2 strong {
        font-weight: 700; }
  .page-basket-order.page-basket-payment .col-4 .btn {
    font-size: 18px;
    justify-content: center; }
  .page-basket-order.page-basket-payment .col-8 p {
    font-size: 17px;
    color: #BABBC3;
    line-height: 1.2941176471; }
  .page-basket-order.page-basket-payment .col-12 .box-inner {
    margin-bottom: 20px; }
  .page-basket-order.page-basket-payment:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 971px;
    width: 50vw;
    height: 1280px;
    background-image: url("../img/desktop/page-basket-order.png");
    background-position: right -8px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

@media screen and (max-width: 1199px) {
  .page-basket-order.page-basket-payment h1 {
    margin-bottom: 30px; }
  .page-basket-order.page-basket-payment .box-orders {
    margin-top: 30px; }
  .page-basket-order.page-basket-payment .box-order-col-1 {
    max-width: 300px;
    flex-basis: 300px; }
  .page-basket-order.page-basket-payment .box-order-col-2 {
    max-width: calc((100% - 300px) / 4);
    flex-basis: calc((100% - 300px) / 4); }
  .page-basket-order.page-basket-payment:before {
    display: none; } }

@media screen and (max-width: 1024px) {
  .page-basket-order.page-basket-payment .box-edit {
    font-size: 17px; }
    .page-basket-order.page-basket-payment .box-edit img {
      width: 20px;
      height: auto; }
  .page-basket-order.page-basket-payment .box-title {
    margin-bottom: 10px; }
  .page-basket-order.page-basket-payment .box-orders {
    margin-top: 0; }
  .page-basket-order.page-basket-payment .box-order {
    font-size: 15px; }
    .page-basket-order.page-basket-payment .box-order-header {
      display: none; }
    .page-basket-order.page-basket-payment .box-order-item .box-order-col-1 strong {
      margin-bottom: 3px; }
    .page-basket-order.page-basket-payment .box-order-footer {
      padding: 15px 0 0; }
      .page-basket-order.page-basket-payment .box-order-footer .box-order-col-1 {
        margin-bottom: 0; }
        .page-basket-order.page-basket-payment .box-order-footer .box-order-col-1:before {
          display: none;
          color: #10162B; }
    .page-basket-order.page-basket-payment .box-order-col {
      margin-bottom: 10px; }
      .page-basket-order.page-basket-payment .box-order-col:before {
        content: attr(data-content);
        font-weight: 600;
        color: #BABBC3;
        margin-right: 10px; }
      .page-basket-order.page-basket-payment .box-order-col-1 {
        max-width: 100%;
        flex-basis: 100%; }
      .page-basket-order.page-basket-payment .box-order-col-2 {
        max-width: 100%;
        flex-basis: 100%;
        text-align: left; }
  .page-basket-order.page-basket-payment .col-8 {
    text-align: center; }
    .page-basket-order.page-basket-payment .col-8 p {
      margin-bottom: 20px; }
  .col-4 .btn {
    height: 56px;
    margin: 0 auto; } }
