.page-domainlanding {
  padding-bottom: 85px; }
  .page-domainlanding h1 {
    padding-top: 12vw;
    margin-bottom: 16px; }
  .page-domainlanding h2 {
    margin-bottom: 26px; }
  .page-domainlanding .hosting-header-box {
    position: relative;
    background-color: #FFFFFF;
    padding: 23px 25px 25px 73px;
    margin-bottom: 46px; }
    .page-domainlanding .hosting-header-box h4 {
      font-weight: 500;
      margin-bottom: 3px; }
    .page-domainlanding .hosting-header-box .gift {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -8px;
      left: 0;
      width: 62px;
      height: 62px;
      background-color: #1A1D39;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box;
      box-shadow: 0 0 6px rgba(242, 29, 164, 0.55); }
  .page-domainlanding:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 875px;
    width: 50vw;
    height: 1169px;
    background-image: url("../img/desktop/domainlanding.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.page-domainlanding-box .reason {
  padding: 18px 27px; }
  .page-domainlanding-box .reason-content h3 {
    font-weight: 500;
    margin-bottom: 5px; }
  .page-domainlanding-box .reason-content p {
    font-size: 15px;
    line-height: 1.4666666667;
    margin-bottom: 0; }

.page-domainlanding-why {
  padding-top: 13.2vw; }
  .page-domainlanding-why h2 {
    line-height: 1.275;
    margin-bottom: 15px; }
  .page-domainlanding-why .lead {
    font-size: 20px;
    color: #707070;
    line-height: 1.75;
    margin-bottom: 22px; }
  .page-domainlanding-why:before {
    top: 0;
    left: 0;
    max-width: 821px;
    height: 1180px;
    background-image: url("../img/desktop/domainlanding-02.png"); }

.page-domainlanding-speed {
  padding-top: 36px; }
  .page-domainlanding-speed:before {
    max-width: 833px;
    height: 1107px;
    background-image: url("../img/desktop/domainlanding-06.png"); }

.page-domainlanding-sitetypes {
  padding-top: 11vw; }
  .page-domainlanding-sitetypes h2 {
    margin-bottom: 64px; }
  .page-domainlanding-sitetypes p {
    font-size: 16px;
    color: #AFAFAF;
    line-height: 1.375; }
  .page-domainlanding-sitetypes .st-box {
    max-width: 20%;
    flex-basis: 20%;
    padding: 0 15px;
    margin-bottom: 58px; }
    .page-domainlanding-sitetypes .st-box-inner {
      background-color: #FFFFFF;
      background-image: url("../img/bg/sitetype-01.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: auto;
      -webkit-border-radius: 44px;
      -moz-border-radius: 44px;
      border-radius: 44px;
      background-clip: padding-box;
      box-shadow: 0 0 20px rgba(152, 38, 137, 0.31);
      padding: 35px 20px 48px; }
    .page-domainlanding-sitetypes .st-box-icon {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      width: 108px;
      height: 108px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box;
      box-shadow: 0 0 20px rgba(0, 36, 209, 0.16);
      margin: 0 auto 25px; }
    .page-domainlanding-sitetypes .st-box-title {
      font-size: 14px;
      color: #10162B;
      line-height: 1.4285714286;
      text-align: center;
      text-transform: uppercase; }
      .page-domainlanding-sitetypes .st-box-title:after {
        content: '';
        display: block;
        width: 62px;
        height: 2px;
        background-color: #EE3194;
        margin: 15px auto 0; }

.page-domainlanding-start {
  padding-top: 5.9vw;
  padding-bottom: 100px; }
  .page-domainlanding-start h2 {
    margin-bottom: 68px; }
  .page-domainlanding-start .box-big {
    max-width: 798px;
    padding-right: 25px;
    background-image: url("../img/dev/page-domainlanding-server.png");
    background-position: left calc(100% - 52px);
    background-repeat: no-repeat;
    background-size: auto; }
    .page-domainlanding-start .box-big .box-content {
      position: relative;
      background-image: url("../img/bg/page-domainlanding.png");
      padding: 19px 35px 33px 73px; }
      .page-domainlanding-start .box-big .box-content:before, .page-domainlanding-start .box-big .box-content:after {
        content: '';
        display: block;
        position: absolute;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto;
        z-index: -1; }
      .page-domainlanding-start .box-big .box-content:before {
        bottom: -178px;
        right: -86px;
        width: 161px;
        height: 193px;
        background-image: url("../img/page-domainlanding-rope-02.svg"); }
      .page-domainlanding-start .box-big .box-content:after {
        top: 33px;
        right: -112px;
        width: 196px;
        height: 178px;
        background-image: url("../img/page-domainlanding-rope-01.svg"); }
    .page-domainlanding-start .box-big .box-title {
      margin-bottom: 16px; }
  .page-domainlanding-start .box-container {
    max-width: 507px;
    width: 100%; }
    .page-domainlanding-start .box-container .box {
      width: 100%;
      padding-left: 25px;
      margin-bottom: 25px; }
      .page-domainlanding-start .box-container .box:first-child {
        margin-bottom: 48px; }
      .page-domainlanding-start .box-container .box-content {
        background-image: url("../img/bg/page-domainlanding2.png");
        padding: 36px 26px 39px; }
      .page-domainlanding-start .box-container .box-title {
        margin-bottom: 13px; }
  .page-domainlanding-start .box-content {
    background-color: #FFFFFF;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box;
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16); }
  .page-domainlanding-start .box-title {
    font-size: 40px;
    font-weight: 500;
    color: #10162B;
    line-height: 1.275;
    text-transform: uppercase; }
  .page-domainlanding-start .box-subtitle {
    font-size: 20px;
    font-weight: 600;
    color: #FF2C9C;
    line-height: 1.5;
    margin-bottom: 10px; }
  .page-domainlanding-start .box-desc {
    font-size: 20px;
    color: #707070;
    line-height: 1.5; }
  .page-domainlanding-start .box-image-tablet, .page-domainlanding-start .box-image-mobil {
    display: none; }
  .page-domainlanding-start .text-help {
    font-size: 16px;
    color: #AFAFAF;
    line-height: 1.375;
    padding-left: 25px; }

.page-domainlanding-security {
  padding-bottom: 148px; }
  .page-domainlanding-security h2 {
    color: #10162B;
    line-height: 1.275;
    padding-top: 10.3vw;
    margin-bottom: 20px; }
  .page-domainlanding-security .lead, .page-domainlanding-security .lead2 {
    font-size: 20px;
    color: #707070;
    line-height: 1.75; }
  .page-domainlanding-security .lead {
    margin-bottom: 35px; }
  .page-domainlanding-security .lead2 {
    margin-bottom: 42px; }
  .page-domainlanding-security:before {
    top: 166px;
    left: unset;
    right: 0;
    max-width: 904px;
    height: 1169px;
    background-image: url("../img/desktop/domainlanding-03.png");
    background-position: right top; }

.page-domainlanding-extras {
  padding-top: 11vw;
  padding-bottom: 7.6vw; }
  .page-domainlanding-extras h2 {
    color: #10162B;
    line-height: 1.275;
    margin-bottom: 15px; }
  .page-domainlanding-extras h4 {
    font-size: 34px;
    color: #10162B;
    line-height: 1.2941176471;
    text-transform: uppercase;
    margin-top: 34px;
    margin-bottom: 29px; }
  .page-domainlanding-extras ul.ul li {
    color: #707070;
    line-height: 1.375; }
    .page-domainlanding-extras ul.ul li:before {
      top: 5px; }
  .page-domainlanding-extras:before {
    top: 0;
    left: 0;
    max-width: 827px;
    height: 1094px;
    background-image: url("../img/desktop/domainlanding-04.png"); }

.page-domainlanding-numbers:before {
  top: -251px; }

.page-domainlanding-order {
  padding-bottom: 131px; }
  .page-domainlanding-order h2 {
    color: #10162B;
    font-size: 56px;
    line-height: 1.1785714286;
    text-transform: uppercase;
    padding-top: 87px;
    margin-bottom: 23px; }
  .page-domainlanding-order h4 {
    color: #10162B;
    line-height: 1.275;
    text-transform: uppercase;
    margin-bottom: 26px; }
  .page-domainlanding-order .col-7 {
    padding-left: 55px;
    margin-left: auto; }
  .page-domainlanding-order .section-subtitle {
    line-height: 1.75;
    margin-bottom: 27px; }
  .page-domainlanding-order .section-subtitle-2 {
    margin-bottom: 43px; }
  .page-domainlanding-order .section-subtitle-bold {
    font-weight: 700;
    color: #10162B; }
    .page-domainlanding-order .section-subtitle-bold a {
      font-weight: 400;
      color: #E83192; }
  .page-domainlanding-order .form-search {
    margin-bottom: 76px; }
  .page-domainlanding-order .btn-link {
    text-align: center;
    margin-bottom: 34px; }
  .page-domainlanding-order:before {
    max-width: 875px;
    height: 1169px;
    left: 0;
    right: auto;
    background-image: url("../img/desktop/domainlanding-05.png");
    background-position: left top; }

.page-domainlanding-testimonials {
  padding-bottom: 80px; }
  .page-domainlanding-testimonials h2 {
    margin-bottom: 120px; }
  .page-domainlanding-testimonials .tm-inner {
    background-image: url("../img/bg/page-domainlanding3.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    background-clip: padding-box;
    box-shadow: 8px 9px 29px rgba(0, 0, 0, 0.16);
    padding: 0 23px 39px; }
  .page-domainlanding-testimonials .tm-name {
    display: flex;
    align-items: flex-end;
    font-size: 21px;
    font-weight: 600;
    color: #10162B;
    line-height: 1.3333333333;
    text-transform: uppercase;
    -ms-transform: translateY(-29px);
    -webkit-transform: translateY(-29px);
    -moz-transform: translateY(-29px);
    -o-transform: translateY(-29px);
    transform: translateY(-29px);
    margin-bottom: -11px; }
    .page-domainlanding-testimonials .tm-name img {
      -ms-transform: translateY(4px);
      -webkit-transform: translateY(4px);
      -moz-transform: translateY(4px);
      -o-transform: translateY(4px);
      transform: translateY(4px);
      margin-right: 5px; }
  .page-domainlanding-testimonials .tm-title {
    font-size: 20px;
    font-weight: 500;
    color: #FF2C9C;
    line-height: 1.5;
    margin-bottom: 3px; }
  .page-domainlanding-testimonials .tm-desc {
    font-size: 15px;
    color: #707070;
    line-height: 1.6666666667; }

@media screen and (max-width: 1366px) {
  .page-domainlanding-sitetypes .st-box-title {
    min-height: 77px; }
  .page-domainlanding-start .box-big {
    max-width: 633px;
    background-size: contain; } }

@media screen and (max-width: 1199px) {
  .hero br {
    display: none; }
  .page-domainlanding-start .box-big {
    max-width: 100%;
    text-align: center;
    background-image: none;
    margin-bottom: 37px; }
    .page-domainlanding-start .box-big .box-content {
      padding: 37px 35px 33px 73px; }
      .page-domainlanding-start .box-big .box-content:after {
        display: none; }
      .page-domainlanding-start .box-big .box-content:before {
        display: none; }
    .page-domainlanding-start .box-big .box-title {
      margin-bottom: 25px; }
  .page-domainlanding-start .box-container {
    max-width: 100%; }
    .page-domainlanding-start .box-container .box {
      position: relative;
      max-width: 507px;
      padding-left: 0;
      margin-left: auto; }
      .page-domainlanding-start .box-container .box:first-child {
        margin-left: 0;
        margin-bottom: 37px; }
        .page-domainlanding-start .box-container .box:first-child:before {
          top: 73px;
          right: -170px; }
        .page-domainlanding-start .box-container .box:first-child:after {
          top: 183px;
          left: 290px; }
        .page-domainlanding-start .box-container .box:first-child:before, .page-domainlanding-start .box-container .box:first-child:after {
          content: '';
          display: block;
          position: absolute;
          width: 181px;
          height: 198px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: auto;
          z-index: -1;
          background-image: url("../img/page-domainlanding-rope-01-tablet.svg"); }
      .page-domainlanding-start .box-container .box-content {
        padding: 33px 26px 29px; }
      .page-domainlanding-start .box-container .box-title {
        margin-bottom: 9px; }
      .page-domainlanding-start .box-container .box-subtitle {
        margin-bottom: 15px; }
  .page-domainlanding-start .box-image-tablet {
    display: block;
    width: 100%;
    height: auto;
    margin-top: -106px; }
  .page-domainlanding-start .box-title {
    font-size: 30px;
    line-height: 1.2666666667; }
  .page-domainlanding-start .text-help {
    max-width: 507px;
    padding-left: 0;
    margin-left: auto; }
    .page-domainlanding-start .text-help.col-6 {
      max-width: 100%;
      flex-basis: 100%;
      margin-top: 30px; } }

@media screen and (max-width: 1024px) {
  .page-domainlanding {
    padding-bottom: 115px; }
    .page-domainlanding h1 {
      padding-top: 36.3vw; }
    .page-domainlanding h2 {
      margin-bottom: 17px; }
    .page-domainlanding:before {
      max-width: 991px;
      width: 100vw;
      height: 1070px;
      background-image: url("../img/tablet/page-domainlanding.png"); }
  .page-domainlanding-box {
    padding-top: 30px; }
    .page-domainlanding-box h2 {
      font-size: 25px;
      line-height: 1.24;
      margin-bottom: 31px; }
      .page-domainlanding-box h2 br {
        display: none; }
    .page-domainlanding-box .lead {
      line-height: 1.25; }
    .page-domainlanding-box .reason {
      padding: 29px 15px 22px; }
      .page-domainlanding-box .reason-content h3 {
        margin-bottom: 13px; }
      .page-domainlanding-box .reason-content p {
        font-size: 16px;
        line-height: 1.25; }
    .page-domainlanding-box:before {
      width: 385px;
      height: 785px;
      background-image: url("../img/tablet/page-domainlanding2.png"); }
  .page-domainlanding-start {
    padding-top: 18.51vw; }
    .page-domainlanding-start .box-image-tablet {
      margin-top: -67px; }
    .page-domainlanding-start .box-container .box:first-child:after {
      left: 50px; }
  .page-domainlanding-sitetypes .row {
    justify-content: center; }
  .page-domainlanding-sitetypes .st-box {
    max-width: 50%;
    flex-basis: 50%; }
  .page-domainlanding-security {
    padding-bottom: 103px; }
    .page-domainlanding-security h2, .page-domainlanding-security .lead, .page-domainlanding-security .lead2 {
      max-width: 66.66667%; }
    .page-domainlanding-security h2 {
      font-size: 35px;
      line-height: 1.2571428571;
      padding-top: 87px;
      margin-bottom: 38px; }
    .page-domainlanding-security .lead2 {
      line-height: 1.25; }
    .page-domainlanding-security .reasons {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px; }
      .page-domainlanding-security .reasons .reason {
        max-width: calc(50% - 30px);
        flex-basis: calc(50% - 30px);
        margin: 0 15px 30px; }
    .page-domainlanding-security:before {
      top: 53px;
      width: 408px;
      height: 697px;
      background-image: url("../img/tablet/page-domainlanding3.png"); }
  .page-domainlanding-extras {
    padding-top: 123px;
    padding-bottom: 0; }
    .page-domainlanding-extras h2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-size: 35px;
      line-height: 1.2571428571;
      text-align: center; }
    .page-domainlanding-extras h4 {
      font-size: 25px;
      line-height: 1.4; }
      .page-domainlanding-extras h4 br {
        display: none; }
    .page-domainlanding-extras ul li {
      margin-bottom: 29px; }
    .page-domainlanding-extras:before {
      top: 78px;
      width: 402px;
      height: 785px;
      background-image: url("../img/tablet/page-domainlanding4.png"); }
  .page-domainlanding-order h2 {
    font-size: 50px;
    line-height: 1.32;
    padding-top: 91px; }
    .page-domainlanding-order h2 br {
      display: none; }
  .page-domainlanding-order .form-search {
    max-width: 572px;
    margin-bottom: 60px;
    margin-left: 0; }
  .page-domainlanding-order:before {
    display: none; }
  .page-domainlanding-testimonials {
    padding-bottom: 0; }
    .page-domainlanding-testimonials .tm-item {
      margin-bottom: 50px; }
    .page-domainlanding-testimonials .tm-inner {
      padding-bottom: 47px; }
    .page-domainlanding-testimonials .tm-name {
      font-size: 30px;
      line-height: 1.3;
      margin-bottom: -7px; }
      .page-domainlanding-testimonials .tm-name img {
        width: 123px;
        height: auto; }
    .page-domainlanding-testimonials .tm-title {
      font-size: 24px;
      line-height: 1.25;
      margin-bottom: 14px; }
    .page-domainlanding-testimonials .tm-desc {
      font-size: 20px;
      line-height: 1.25; }
  .page-domainlanding-speed:before {
    max-width: 385px;
    height: 572px;
    background-image: url("../img/tablet/page-domainlanding6.png"); } }

@media screen and (max-width: 660px) {
  .page-domainlanding {
    padding-bottom: 0; }
    .page-domainlanding h1 {
      font-size: 21px;
      line-height: 1.2857142857;
      text-align: center;
      padding-top: 0;
      margin-bottom: 22px; }
    .page-domainlanding h2 {
      font-size: 14px;
      line-height: 1.3571428571;
      text-align: center;
      margin-bottom: 36px; }
    .page-domainlanding .hosting-header-box {
      padding: 14px 15px 14px 60px; }
      .page-domainlanding .hosting-header-box h4 {
        font-size: 13px;
        line-height: 1;
        margin-bottom: 4px; }
      .page-domainlanding .hosting-header-box p {
        font-size: 13px;
        line-height: 1.2307692308; }
      .page-domainlanding .hosting-header-box .gift {
        width: 40px;
        height: 40px;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 10px; }
        .page-domainlanding .hosting-header-box .gift img {
          width: 20px;
          height: auto; }
    .page-domainlanding .btn {
      margin: 0 auto; }
    .page-domainlanding:before {
      display: none; }
  .page-domainlanding-box .after-line-tablet:after {
    margin-left: auto;
    margin-right: auto; }
  .page-domainlanding-box .reason {
    padding: 8px 8px; }
    .page-domainlanding-box .reason-content h3 {
      margin-bottom: 4px; }
    .page-domainlanding-box .reason-content p {
      font-size: 13px;
      line-height: 1.2307692308; }
  .page-domainlanding-why h2 {
    font-size: 21px;
    line-height: 1.2857142857;
    text-align: center;
    padding-top: 15px;
    margin-bottom: 19px; }
  .page-domainlanding-why .lead {
    font-size: 14px;
    line-height: 1.3571428571;
    text-align: center;
    margin-bottom: 10px; }
  .page-domainlanding-why:before {
    display: none; }
  .page-domainlanding-start {
    padding-top: 23px;
    padding-bottom: 0; }
    .page-domainlanding-start .box-subtitle, .page-domainlanding-start .box-desc {
      font-size: 13px;
      line-height: 1.1538461538; }
    .page-domainlanding-start .box-big {
      text-align: left;
      padding: 0 15px; }
      .page-domainlanding-start .box-big .box-content {
        padding: 10px 15px 14px; }
        .page-domainlanding-start .box-big .box-content:before, .page-domainlanding-start .box-big .box-content:after {
          display: block;
          width: 91px;
          height: 56px; }
        .page-domainlanding-start .box-big .box-content:before {
          bottom: -43px;
          right: unset;
          left: 17%;
          background-image: url("../img/page-domainlanding-rope-01-mobil.svg"); }
        .page-domainlanding-start .box-big .box-content:after {
          top: unset;
          right: unset;
          bottom: -43px;
          left: 59%;
          background-image: url("../img/page-domainlanding-rope-02-mobil.svg"); }
      .page-domainlanding-start .box-big .box-title {
        font-size: 16px;
        line-height: 1.25;
        margin-bottom: 7px; }
    .page-domainlanding-start .box-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding: 0 8.5px; }
      .page-domainlanding-start .box-container .box {
        max-width: 50%;
        flex-basis: 50%;
        padding: 0 6.5px; }
        .page-domainlanding-start .box-container .box:before, .page-domainlanding-start .box-container .box:after {
          display: none !important; }
        .page-domainlanding-start .box-container .box-content {
          height: 100%;
          padding: 9px 15px 41px; }
        .page-domainlanding-start .box-container .box-title {
          font-size: 20px;
          line-height: 1.25;
          margin-bottom: 7px; }
    .page-domainlanding-start .box-image-mobil {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      margin-top: -67px;
      z-index: 1; }
    .page-domainlanding-start .box-image-tablet {
      display: none; }
    .page-domainlanding-start .text-help {
      position: absolute;
      bottom: 17px;
      max-width: 50%;
      flex-basis: 50%;
      font-size: 10px;
      line-height: 1.4;
      padding-right: 15px;
      margin-left: 15px; }
      .page-domainlanding-start .text-help.col-6 {
        position: static; }
  .page-domainlanding-sitetypes .st-box {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 24px; }
    .page-domainlanding-sitetypes .st-box-inner {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      background-clip: padding-box;
      padding: 23px 20px 13px; }
    .page-domainlanding-sitetypes .st-box-icon {
      width: 72px;
      height: 72px;
      margin: 0 auto 15px; }
      .page-domainlanding-sitetypes .st-box-icon img {
        width: auto;
        height: 36px; }
    .page-domainlanding-sitetypes .st-box-title {
      height: auto !important;
      line-height: 17px; }
      .page-domainlanding-sitetypes .st-box-title:after {
        height: 1px;
        margin: 7px auto 0; }
  .page-domainlanding-security {
    padding-top: 0;
    padding-bottom: 25px; }
    .page-domainlanding-security h2, .page-domainlanding-security .lead, .page-domainlanding-security .lead2 {
      max-width: 100%;
      flex-basis: 100%; }
    .page-domainlanding-security h2 {
      font-size: 21px;
      line-height: 1.2380952381;
      padding-top: 0;
      margin-top: -18px;
      margin-bottom: 15px; }
    .page-domainlanding-security .lead, .page-domainlanding-security .lead2 {
      font-size: 14px;
      line-height: 1.3571428571;
      text-align: center; }
    .page-domainlanding-security .lead {
      margin-bottom: 13px; }
    .page-domainlanding-security .lead2 {
      margin-bottom: 23px; }
    .page-domainlanding-security .reasons .reason {
      max-width: 100%;
      flex-basis: 100%;
      margin: 0 15px 13px; }
    .page-domainlanding-security .mobil-picture {
      width: auto;
      height: 100%;
      margin-top: -52px;
      margin-left: auto; }
  .page-domainlanding-extras {
    padding-top: 0; }
    .page-domainlanding-extras h2 {
      position: static;
      font-size: 21px;
      line-height: 1.2380952381;
      padding-top: 6px;
      margin-bottom: 18px; }
    .page-domainlanding-extras h4 {
      font-size: 21px;
      line-height: 1.2857142857;
      margin-top: 29px;
      margin-bottom: 19px; }
    .page-domainlanding-extras ul.ul li {
      font-size: 13px;
      line-height: 1.6923076923; }
      .page-domainlanding-extras ul.ul li:last-child {
        margin-bottom: 3px; }
  .page-domainlanding-order {
    padding-bottom: 34px; }
    .page-domainlanding-order h2 {
      font-size: 21px;
      line-height: 1.2380952381;
      text-align: center;
      padding-top: 0;
      margin-bottom: 14px; }
    .page-domainlanding-order .section-subtitle {
      line-height: 1.3571428571;
      padding-right: 0;
      margin-bottom: 9px; }
    .page-domainlanding-order .section-subtitle-2 {
      margin-bottom: 19px; }
    .page-domainlanding-order h4 {
      text-align: center;
      margin-bottom: 21px; }
    .page-domainlanding-order .form-search {
      margin-bottom: 49px; }
    .page-domainlanding-order .btn-link {
      font-size: 10px;
      line-height: 1.2;
      max-width: 140px;
      height: 39px;
      margin: 0 auto 30px; }
    .page-domainlanding-order .mobil-picture {
      width: auto; }
  .page-domainlanding-testimonials h2 {
    margin-bottom: 47px; }
  .page-domainlanding-testimonials .tm-item {
    margin-bottom: 68px; }
  .page-domainlanding-testimonials .tm-inner {
    padding-bottom: 25px; }
  .page-domainlanding-testimonials .tm-name {
    font-size: 17px;
    line-height: 1.3529411765;
    margin-bottom: -17px; }
    .page-domainlanding-testimonials .tm-name img {
      width: 86px; }
  .page-domainlanding-testimonials .tm-title {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 0; }
  .page-domainlanding-testimonials .tm-desc {
    font-size: 13px;
    line-height: 1.9230769231; }
  .page-domainlanding-speed {
    padding-bottom: 40px; } }
