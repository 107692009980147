.services-item {
  margin-bottom: 30px; }

.services-inner {
  background-color: #FFFFFF;
  background-image: url("../img/bg/page-basket-additional.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
  background-clip: padding-box;
  box-shadow: 0 0 25px rgba(152, 38, 137, 0.16);
  padding: 39px 30px; }

.services-icon {
  margin: 0 auto 17px; }

.services-title {
  font-weight: 600;
  color: #707070;
  text-align: center;
  margin-bottom: 22px; }
  .services-title:after {
    content: '';
    display: block;
    width: 74px;
    height: 3px;
    background-color: #19D08C;
    margin: 22px auto 0; }

.services-price {
  font-weight: 700;
  color: #10162B;
  text-align: center;
  margin-bottom: 16px; }
